import { createSlice } from '@reduxjs/toolkit'
import {isLoggedIn} from "./index";

const initialState = {
    isLoggedIn: isLoggedIn()
}

export const authSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        login: (state, action) => {
            localStorage.setItem("auth-token", action.payload)
            state.isLoggedIn = true
        },
        logout: (state) => {
            localStorage.removeItem("auth-token")
            state.isLoggedIn = false
        },
        resetAuth: () => {
            return {...initialState}
        }
    },
})

export const {
    login,
    logout ,
    resetAuth
} = authSlice.actions

export default authSlice.reducer