import React, { useState } from 'react';
import "./checkout.scss";
import NewBackground from "../../img/NewBackground.webp";
import InputMask from "react-input-mask";
import DropdownSelector from "../../Components/DropdownSelector";
import Cities from "../../Services/cities.json";
import States from "../../Services/states.json";
import {FaEye, FaEyeSlash} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {FaExclamationTriangle} from "react-icons/fa";

function Checkout(props) {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        zip: "",
        address: "",
        addressNumber: "",
        addressComplement: "",
        addressCity: "",
        addressState: ""
    });

    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const getCities = () => {
        const state = Cities['estados'].find(state => state.sigla === formData['addressState'])
        console.log("ESTADOS", Cities['estados'], state?.cidades)
        return state ? state.cidades : []
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleBlur = async (e) => {
        const { name, value } = e.target;
        if (name === 'zip' && value.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${value}/json/`);
                if (response.ok) {
                    const data = await response.json();
                    setFormData({
                        ...formData,
                        address: data.logradouro,
                        addressCity: data.localidade,
                        addressState: data.uf
                    });
                } else {
                    console.error('Erro ao buscar o CEP');
                }
            } catch (error) {
                console.error('Erro ao buscar o CEP:', error);
            }
        }
    };

    const handleContinue = () => {
        const errors = [];
        if (!formData.name.trim()) {
            errors.push("O campo Nome é obrigatório.");
        }
        if (!formData.phone.trim()) {
            errors.push("O campo Telefone é obrigatório.");
        }
        if (!formData.email.trim()) {
            errors.push("O campo Email é obrigatório.");
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.push("O Email inserido é inválido.");
        }
        if (!formData.password.trim()) {
            errors.push("O campo Senha é obrigatório.");
        }
        if (formData.password !== formData.passwordConfirmation) {
            errors.push("As senhas não coincidem.");
        }
        if (!formData.zip.trim()) {
            errors.push("O campo CEP é obrigatório.");
        }

        setErrors(errors);
        if (errors.length > 0) {
            return;
        }

        props.handleContinue(formData)
    };

    return (
        <div className={"Checkout"}>
            <img src={NewBackground} alt={"background image"} className={"CheckoutBackground"}/>
            <div className={"CheckoutContainer"}>
                <div className={"CheckoutHeader"}>
                    Cadastro
                </div>
                <form className={"CheckoutBody"}>
                    <div className={"CheckoutBodySection"}>
                        <h3 className={"CheckoutBodySectionTitle"}>Informações Pessoais</h3>
                        <div className={"CheckoutBodySectionInput"}>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="name">Nome</label>
                                <InputMask
                                    name="name"
                                    placeholder={"Nome"}
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="phone">Telefone</label>
                                <InputMask
                                    name="phone"
                                    placeholder={"Telefone"}
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="email">Email</label>
                                <InputMask
                                    name="email"
                                    placeholder={"Email"}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="Password">Crie sua senha</label>
                                <div className="PasswordContainer">
                                    <InputMask
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder={"Senha"}
                                        value={formData.password}
                                        onChange={handleChange}
                                        style={{width: "80%"}}
                                    />
                                    <div className="PasswordToggle" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaEyeSlash size={25}/> : <FaEye size={25}/>}
                                    </div>
                                </div>
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="Password">Confirme a senha</label>
                                <div className="PasswordContainer">
                                    <InputMask
                                        name="passwordConfirmation"
                                        type={showPassword ? "text" : "password"}
                                        placeholder={"Confirme a Senha"}
                                        value={formData.passwordConfirmation}
                                        onChange={handleChange}
                                        style={{width: "80%"}}
                                    />
                                    <div className="PasswordToggle" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaEyeSlash size={25}/> : <FaEye size={25}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"CheckoutBodySection"}>
                        <h3 className={"CheckoutBodySectionTitle"}>Endereço</h3>
                        <div className={"CheckoutBodySectionInput"}>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="zip">CEP</label>
                                <InputMask
                                    name="zip"
                                    placeholder={"CEP"}
                                    value={formData.zip}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="address">Endereço</label>
                                <InputMask
                                    name="address"
                                    placeholder={"Endereço"}
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="addressNumber">Número</label>
                                <InputMask
                                    name="addressNumber"
                                    placeholder={"Número"}
                                    value={formData.addressNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField">
                                <label htmlFor="addressComplement">Complemento</label>
                                <InputMask
                                    name="addressComplement"
                                    placeholder={"Complemento"}
                                    value={formData.addressComplement}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="CheckoutBodySectionInputField Split">
                                <div className="CheckoutBodySectionInputField first">
                                    <label htmlFor="addressState">Estado</label>
                                    <DropdownSelector
                                        options={States}
                                        value={formData['addressState']}
                                        checkout
                                        onChange={(value) => handleChange({target: {name: "addressState", value: value}})}
                                    />
                                </div>
                                <div className="CheckoutBodySectionInputField second">
                                    <label htmlFor="addressCity" id={"CityLabel"}>Cidade</label>
                                    <DropdownSelector
                                        options={getCities()}
                                        value={formData['addressCity']}
                                        checkout
                                        onChange={(value) => handleChange({target: {name: "addressCity", value: value}})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className={"CheckoutFooter"}>
                    <button className={"CheckoutFooterButton return"} onClick={props.handleGoBack}>
                        Voltar
                    </button>
                    <button className={"CheckoutFooterButton"} onClick={handleContinue}>
                        Continuar
                    </button>
                </div>
            </div>
            {
                errors.length > 0 && <div className={"CheckoutContainer Errors"}>
                    <div className={"CheckoutHeader"}>
                        Erros
                    </div>
                    <div className={"ErrorsContainer"}>
                        {errors.map(error => {
                            return <div className={"Error"}>
                                <div className={"ErrorAlert"}>
                                    <FaExclamationTriangle color={"yellow"} size={20}/>
                                </div>
                                <div className={"ErrorText"}>
                                    {error}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }
        </div>
    );
}

export default Checkout;
