import DashboardScreenshot from "../../img/Screenshot-Dashboard.webp";
import GoalsScreenshot from "../../img/Goals Screenshot.webp";
import SubmitScreenshot from "../../img/Submit Screenshot.webp";
import "./IndexPage.scss";
import {useEffect, useRef, useState} from "react";
import request from "../../Services/Request";
import Navbar from "./Navbar";
import InputMask from "react-input-mask";

function IndexPage() {

    const [teamPlans, setTeamPlans] = useState(false)
    const [individualPlans, setIndividualPlans] = useState(false)

    const [contactName, setContactName] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactText, setContactText] = useState('');

    useEffect(() => {
        request("plans/", "GET", null, true).then(res => {
            setIndividualPlans(res.filter(plan => plan.type === "I"))
            setTeamPlans(res.filter(plan => plan.type === "T"))
        })
    }, []);

    const handleContactSubmit = (e) => {
        e.preventDefault();

        const requestData = {
            name: contactName,
            phone: contactPhone,
            email: contactEmail,
            message: contactText
        }

        request("message/", "POST", requestData, true).then(res => {
            if (res) {
                alert("Mensagem enviada com sucesso!")
                setContactName('');
                setContactPhone('');
                setContactEmail('');
                setContactText('');
            }
        })
    }

    return (
        <>
            <Navbar/>
            <section id={"Hero"}>
                <div className={"Left"}>
                    <p>Conheça sua nova forma de administrar produção</p>
                </div>
                <div className={"Right"}>
                    <img src={DashboardScreenshot} alt={"Screenshot da Tela Inicial"}/>
                </div>
            </section>
            <section id={"Features"}>
                <h2 className="SectionTitle">Administre sua produção de forma simplificada e ágil</h2>
                <div className={"OptimusPrime"}>
                    <div className={"Item"}>
                        <img src={DashboardScreenshot} alt={"Screenshot da Tela Inicial"}/>
                        <div className={"TextContainer"}>
                            <p>Fácil controle da produção diária e mensal</p>
                            <p>Resumo completo da produção do dia na tela inicial, sendo possível visualizar histórico e comparar períodos</p>
                        </div>
                    </div>
                    <div className={"Item Reverse"}>
                        <div className={"Item Content"}>
                            <img src={GoalsScreenshot} alt={"Screenshot da Tela Inicial"}/>
                            <div className={"TextContainer"}>
                                <p>Controle de objetivos</p>
                                <p>Defina e monitore objetivos facilmente, tanto a nível individual quanto em equipe.</p>
                            </div>
                        </div>
                    </div>
                    <div className={"Item"}>
                        <img src={SubmitScreenshot} alt={"Screenshot da Tela Inicial"}/>
                        <div className={"TextContainer"}>
                            <p>Uso rápido e simples</p>
                            <p>Para alimentar o sistema, basta acessar o site, preencher a produção e enviar</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"Plans"}>
                <h2 className={"SectionTitle"}>Conheça nosso plano</h2>
                <div className={"PlanTablesContainer"}>
                    {/*<div className={"PlanTableContainer"}>*/}
                    {/*    <div className={"PlanTable"}>*/}
                    {/*        <div className={"Title"}>Equipe</div>*/}
                    {/*        <div className={"Price"}>*/}
                    {/*            <div className={"Box"}>*/}
                    {/*                <div>R$</div>*/}
                    {/*                <div>49,99</div>*/}
                    {/*                <div>/Mês</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={"Benefits"}>*/}
                    {/*            <ul>*/}
                    {/*                <li>Controle Produção</li>*/}
                    {/*                <li>Controle Produção</li>*/}
                    {/*                <li>Controle Produção</li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*        <div className={"Button"}>*/}
                    {/*            <button>Quero esse</button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <p>Ideal para corretores e gerentes que querem ter controle total e organizado</p>*/}
                    {/*</div>*/}
                    <div className={"PlanTableContainer"}>
                        <div className={"PlanTable"}>
                            <div className={"Title"}>Padrão</div>
                            <div className={"Price"}>
                                <div className={"Box"}>
                                    <div>R$</div>
                                    <div>64,99</div>
                                    <div>/Mês</div>
                                </div>
                            </div>
                            <div className={"Benefits"}>
                                <ul>
                                    <li>Controle de Produção</li>
                                    <li>Acompanhamento de Objetivos</li>
                                    {/*<li></li>*/}
                                </ul>
                            </div>
                            <div className={"Button"}>
	    			{/* <button>Quero esse</button> */}
                            </div>
                        </div>
                        {/*<p>Coordena uma equipe? Então esse é o plano ideal para você</p>*/}
                    </div>
                </div>
            </section>
            <section id={"Contact"}>
                <div className={"TitleContainer"}>
                    <h2 className={"SectionTitle"}>Entre em contato conosco!</h2>
                </div>
                <div className={"ContactFormContainer"}>
                    <form onSubmit={handleContactSubmit}>
                        <div className={"InputFields"}>
                            <div className={"LeftSection"}>
                                <InputMask
                                    name="name"
                                    placeholder={"Nome"}
                                    value={contactName}
                                    onChange={(e) => setContactName(e.target.value)}
                                />
                                <InputMask
                                    name="phone"
                                    placeholder={"Telefone"}
                                    mask={"(99) 9 9999-9999"}
                                    value={contactPhone}
                                    onChange={(e) => setContactPhone(e.target.value)}
                                />
                                <InputMask
                                    name="email"
                                    placeholder={"E-mail"}
                                    value={contactEmail}
                                    onChange={(e) => setContactEmail(e.target.value)}
                                />
                            </div>
                            <div className={"RightSection"}>
                                <textarea
                                    name="text"
                                    placeholder={"Como podemos te ajudar?"}
                                    value={contactText}
                                    onChange={(e) => setContactText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={"Submit"}>
                            <input type={"submit"} value={"Enviar Mensagem"}/>
                        </div>
                    </form>
                    <div className={"Footer"}>
                        <p>© 2024 Todos os direitos reservados, Masterprod</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default IndexPage;
