import React, {useEffect, useState} from 'react';
import request from "../../Services/Request";
import {useDispatch, useSelector} from "react-redux";
import {
    Collapse,
    IconButton,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Table,
    Box,
    TextField
} from "@mui/material";
import {FaArrowDown, FaArrowUp} from "react-icons/fa6";
import {removeLead, setLeads} from "./leadsSlice";
import "./styles.scss"
import UpdateLead from "./UpdateLead";
import RemoveLead from "./RemoveLead";
import {setAlert} from "../../AppSlice";
import DropdownSelector from "../../Components/DropdownSelector";
import Modal from "../../Components/Modal";
import {months, years} from "../../Components/History"
import dayjs from "dayjs";

function Leads(props) {

    const [clientQuery, setClientQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1);
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [selectedMember, setSelectedMember] = useState(null);


    const {userRole} = useSelector(state => state.app);
    const {sectors} = useSelector(state => state.sectors);
    const {leads} = useSelector(state => state.leads);
    const {members} = useSelector(state => state.members);

    const dispatch = useDispatch();

    console.log("LEADS IS", leads)

    const fetchLeads = async () => {
        const leads = await request("leads/", "GET")
        if (leads) {
            dispatch(setLeads(leads))
        }
    }

    useEffect(() => {
        fetchLeads();
    }, []);

    const filteredLeads = leads ? leads.filter(lead => {
        const leadDate = dayjs(lead.created_at);
        console.log("LEAD FILTER", leadDate.month() + 1 === selectedMonth, (leadDate.year() === selectedYear)
        , (!selectedSector || lead.sector === selectedSector)
        , (!clientQuery || lead.client.toLowerCase().includes(clientQuery.toLowerCase())))
        return (leadDate.month() + 1 === selectedMonth)
            && (leadDate.year() === selectedYear)
            && (!selectedSector || lead.sector === selectedSector)
            && (!selectedStatus || lead.status === selectedStatus)
            && (!selectedMember || lead.member === selectedMember)
            && (!clientQuery || lead.client.toLowerCase().includes(clientQuery.toLowerCase()))
    }) : []

    return (
        <div className={"Leads"}>
            <h3 className={"Title"}>Prospecções</h3>
            { sectors && leads && userRole && members && <div className={"Content"}>
                <div className={"Filters"}>
                    <TextField
                        label="Cliente"
                        hiddenLabel
                        value={clientQuery}
                        onChange={(e) => setClientQuery(e.target.value)}
                    />
                    <DropdownSelector
                        id={"LeadsStatus"}
                        options={[
                            {
                                key: null,
                                value: "Todos",
                            },
                            {
                                key: "W",
                                value: "Ganhou"
                            },
                            {
                                key: "L",
                                value: "Perdeu"
                            },
                            {
                                key: "P",
                                value: "Em andamento"
                            }
                        ]}
                        label={"Status"}
                        value={[
                            {
                                key: null,
                                value: "Todos"
                            },
                            {
                                key: "W",
                                value: "Ganhou"
                            },
                            {
                                key: "L",
                                value: "Perdeu"
                            },
                            {
                                key: "P",
                                value: "Em andamento"
                            }
                        ].find(x => x.key === selectedStatus)?.value}
                        onChange={setSelectedStatus}
                    />
                    <DropdownSelector
                        options={[{key: null, value: "Todos"}, ...members.map(member => ({key: member.id, value: member.name}))]}
                        value={[{key: null, name: "Todos"}, ...members.map(member => ({key: member.id, name: member.name}))].find(member => member.key === selectedMember)?.name}
                        label={"Corretor"}
                        id={"LeadsMember"}
                        onChange={setSelectedMember}
                    />
                    <DropdownSelector
                        options={[{key: null, value: "Todos"}, ...sectors.map(sector => ({key: sector.id, value: sector.name}))]}
                        value={[{key: null, name: "Todos"}, ...sectors.map(sector => ({key: sector.id, name: sector.name}))].find(sector => sector.key === selectedSector)?.name}
                        label={"Agência"}
                        id={"LeadsSector"}
                        onChange={setSelectedSector}
                    />
                    <DropdownSelector
                        id={"leads-month"}
                        label={"Selecione o mês"}
                        options={months}
                        value={months.find(month => month.key === selectedMonth)?.value}
                        onChange={(value) => setSelectedMonth(value)}
                    />
                    <DropdownSelector
                        id={"leads-year"}
                        label={"Selecione o ano"}
                        options={years}
                        value={years.find(year => year === selectedYear)}
                        onChange={(value) => setSelectedYear(value)}
                    />
                </div>
                <TableContainer component={Paper}  style={{maxHeight: "90%", marginTop: 10}}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{padding: 5}}/>
                                <TableCell style={{padding: 5}}>Ações</TableCell>
                                <TableCell style={{padding: 5}}>Enviado Em</TableCell>
                                <TableCell style={{padding: 5}}>Cliente</TableCell>
                                <TableCell style={{padding: 5}}>Status</TableCell>
                                <TableCell style={{padding: 5}}>Agência</TableCell>
                                <TableCell style={{padding: 5}}>Previsão Débito</TableCell>
                                {userRole === "admin" && <TableCell style={{padding: 5}}>Corretor</TableCell>}
                                <TableCell style={{padding: 5}}>Etapa</TableCell>
                                <TableCell style={{padding: 5}}>Observação</TableCell>
                            </TableRow>
                        </TableHead>
                        { filteredLeads && <TableBody>
                            {filteredLeads.map((row) => (
                                <Row key={row.id} row={row} />
                            ))}
                        </TableBody> }
                    </Table>
                </TableContainer>
            </div> }
        </div>
    );
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const {userRole} = useSelector(state => state.app);
    const {members} = useSelector(state => state.members);
    const {products} = useSelector(state => state.products);
    const {sectors} = useSelector(state => state.sectors);

    const dispatch = useDispatch();

    const handleRemove = (id) => {
        request(`leads/${id}/`, "DELETE").then((response) => {
            if (response.success) {
                dispatch(setAlert({
                    message: "Prospecção removida com sucesso",
                    severity: "success"
                }))
                dispatch(removeLead(id))
                return true
            }
        })
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ padding: 5}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <FaArrowUp /> : <FaArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell style={{ padding: 5, display: 'flex', flexDirection: 'row', columnGap: 5 }}>
                    <UpdateLead initial={row}/>
                    <RemoveLead onConfirm={() => handleRemove(row.id)}/>
                </TableCell>
                <TableCell style={{ padding: 5}}>{dayjs(row.created_at).format("DD/MM/YYYY")}</TableCell>
                <TableCell style={{ padding: 5}} component="th" scope="row">
                    {row.client}
                </TableCell>
                <TableCell style={{ padding: 5}}>{[
                    {key: "P", value: "Em Andamento"},
                    {key: "W", value: "Ganhou"},
                    {key: "L", value: "Perdeu"}
                ].find(statusf => statusf.key === row.status)?.value}</TableCell>
                <TableCell style={{ padding: 5}}>{sectors ? sectors.find(sec => sec.id === row.sector)?.name : ""}</TableCell>
                <TableCell style={{ padding: 5}}>{[
                    {key: 1, value: "Janeiro"},
                    {key: 2, value: "Fevereiro"},
                    {key: 3, value: "Março"},
                    {key: 4, value: "Abril"},
                    {key: 5, value: "Maio"},
                    {key: 6, value: "Junho"},
                    {key: 7, value: "Julho"},
                    {key: 8, value: "Agosto"},
                    {key: 9, value: "Setembro"},
                    {key: 10, value: "Outubro"},
                    {key: 11, value: "Novembro"},
                    {key: 12, value: "Dezembro"}
                ].find(month => month.key === row.month)?.value}</TableCell>
                {userRole === "admin" && <TableCell style={{ padding: 5}}>{members && members.find(member => member.id === row.member)?.name}</TableCell>}
                <TableCell style={{ padding: 5}}>{row.step}</TableCell>
                <TableCell style={{ padding: 5}}>{row.obs}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Produtos
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Produto</TableCell>
                                        <TableCell>Valor</TableCell>
                                        <TableCell>Processo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((historyRow) => (
                                        <TableRow key={historyRow.id}>
                                            <TableCell component="th" scope="row">
                                                {products && products.find(product => product.id === historyRow.product)?.name}
                                            </TableCell>
                                            <TableCell>{historyRow.amount}</TableCell>
                                            <TableCell>{historyRow.code}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default Leads;

export const ExportLead = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    // const [reportType, setReportType] = useState(null);
    const {selectedTeam} = useSelector(state => state.app)
    const dispatch = useDispatch();
    console.log("MONTHS", months, years)

    const handleExportReport = async() => {
        if (!selectedMonth) {
            dispatch(setAlert({
                message: "Selecione o mês para gerar o relatório",
                severity: "warning"
            }))
        }

        if (!selectedYear) {
            dispatch(setAlert({
                message: "Selecione o ano para gerar o relatório",
                severity: "warning"
            }))
        }

        // if (!reportType) {
        //     dispatch(setAlert({
        //         message: "Selecione o tipo de arquivo para gerar o relatório",
        //         severity: "warning"
        //     }))
        // }

        const reportData = {
            month: selectedMonth,
            year: selectedYear,
            // type: reportType,
            team: selectedTeam.id
        }
        const searchParams = new URLSearchParams(reportData);

        const res = await fetch(
            `https://api.masterprod.com.br/reports/leads/?${searchParams.toString()}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("auth-token")}`
                }
            }
        ).then((response) => {
            return response.blob()
        })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                const monthName = months.find(month => month.key === selectedMonth).value;
                link.setAttribute(
                    'download',
                    `Pipeline de ${monthName}.xlsx`,
                );
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
        // if (res.success) {
        return true
        // }
        // return false
    }

    const modalContent = <div className={"ProductionReport"}>
        <div className={"Section"}>
            <DropdownSelector
                options={months}
                label={"Mês"}
                value={months[selectedMonth - 1]?.value}
                onChange={(value) => setSelectedMonth(value)}
            />
        </div>
        <div className={"Section"}>
            <DropdownSelector
                options={years}
                label={"Ano"}
                value={selectedYear}
                onChange={(value) => setSelectedYear(value)}
            />
        </div>
        {/*<div className={"Section FileType"}>*/}
        {/*    <span className={"FileType Title"}>Tipo de Arquivo</span>*/}
        {/*    <div className={"FileType Content"}>*/}
        {/*        <Button*/}
        {/*            onClick={() => {setReportType(reportType === "excel" ? false : "excel")}}*/}
        {/*            style={reportType === "excel" ? {backgroundColor: "#3f2527"}: {}}*/}
        {/*        >*/}
        {/*            <FaFileExcel/>*/}
        {/*            <span>Excel</span>*/}
        {/*        </Button>*/}
        {/*        <Button*/}
        {/*            onClick={() => {setReportType(reportType === "pdf" ? false : "pdf")}}*/}
        {/*            style={reportType === "pdf" ? {backgroundColor: "#3f2527"}: {}}*/}
        {/*        >*/}
        {/*            <FaFilePdf/>*/}
        {/*            <span>PDF</span>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>

    return <Modal
        buttonText={"Gerar Relatório"}
        title={"Relatório de Prospecções"}
        content={modalContent}
        style={{
            width: "25dvw",
        }}
        onConfirm={handleExportReport}
    />
}
