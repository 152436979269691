import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    path: '/',
    searchParams: '',
    user: false,
    teams: false,
    selectedTeam: false,
    userRole: false,
    productions: [],
    isRegistering: false,
    isRecovering: false,
    alert: {
        severity: "",
        message: ""
    },
    showSidebar: false,
    emptyProductionFlags: false,
    teamsEmptyProductionFlags: false,
    activeDropdown: null
}

export const appSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setPath: (state, action) => {
            state.path = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setTeams: (state, action) => {
            state.teams = action.payload
        },
        setSelectedTeam: (state, action) => {
            state.selectedTeam = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        setProductions: (state, action) => {
            console.log("SETTINGS PRODUCTS", action.payload)
            state.productions = action.payload
        },
        updateProduction: (state, action) => {
            state.productions = state.productions.map(production => {
                if (production.id === action.payload.id) {
                    return action.payload;
                }
                return production;
            });
        },
        addProductions: (state, action) => {
            state.productions = [...state.productions, ...action.payload]
        },
        removeProduction: (state, action) => {
            state.productions = state.productions.filter(production => production.id !== action.payload)
        },
        setIsRegistering: (state, action) => {
            state.isRegistering = action.payload
        },
        setIsRecovering: (state, action) => {
            state.isRecovering = action.payload
        },
        setAlert: (state, action) => {
            state.alert = action.payload
        },
        setSearchParams: (state, action) => {
            state.searchParams = action.payload
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload
        },
        setTeamsEmptyProductionFlags: (state, action) => {
            state.teamsEmptyProductionFlags = action.payload
        },
        setEmptyProductionFlags: (state, action) => {
            state.emptyProductionFlags = action.payload
        },
        addEmptyProductionFlag: (state, action) => {
            state.emptyProductionFlags = [...state.emptyProductionFlags, action.payload]
        },
        removeEmptyProductionFlag: (state, action) => {
            state.emptyProductionFlags = state.emptyProductionFlags.filter(production => production.id !== action.payload)
        },
        setActiveDropdown: (state, action) => {
            state.activeDropdown = action.payload;
        },
        resetApp: () => {
            return {...initialState}
        }
    },
})

export const {
    setPath,
    setUser,
    setTeams,
    setSelectedTeam,
    setUserRole,
    setProductions,
    updateProduction,
    addProductions,
    setIsRegistering,
    setIsRecovering,
    setAlert,
    removeProduction,
    setShowSidebar,
    setTeamsEmptyProductionFlags,
    setEmptyProductionFlags,
    addEmptyProductionFlag,
    removeEmptyProductionFlag,
    setActiveDropdown,
    resetApp
} = appSlice.actions

export default appSlice.reducer