import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    leads: false
}

export const leadsSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        setLeads: (state, action) => {
            state.leads = action.payload
        },
        addLead: (state, action) => {
            state.leads = [...state.leads, action.payload]
        },
        removeLead: (state, action) => {
            state.leads = state.leads.filter(lead => lead.id !== action.payload)
        },
        updateLead: (state, action) => {
            const sectorIndex = state.leads.findIndex(lead => lead.id === action.payload.id)
            if (sectorIndex > -1) {
                state.leads[sectorIndex] = action.payload
            }
        },
        resetLeads: () => {
            return {...initialState}
        }
    }
})

export const {
    setLeads,
    addLead,
    removeLead,
    updateLead,
    resetLeads
} = leadsSlice.actions

export default leadsSlice.reducer;
