import React, {useEffect, useState} from 'react';
import './style.scss';

const ProgressBar = (props) => {
    const {compact} = props
    const [completed, setCompleteted] = useState(0)

    useEffect(() => {
        setTimeout(() => setCompleteted(props.completed), 10)
    }, [props.completed]);

    return <div className={"ProgressBarContainer"} style={compact ? {height: 35} : {}}>
        <div className={"ProgressBarTotal"}>
            <div className={"ProgressBarFill"} style={{width: `${completed}%`}}></div>
        </div>
        <span className={"ProgressBarLabel"}>{completed.toFixed(0)}%</span>
    </div>
};

export default ProgressBar;
