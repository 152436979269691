import {Box} from "@mui/system";
import {Button, Modal, TextField} from "@mui/material";
import React, {useState} from "react";
import request from "../../Services/Request";
import {useDispatch, useSelector} from "react-redux";
import {ThreeDots} from "react-loader-spinner";
import {addInvite, addMember} from "./MembersSlice";

const CreateMember = () => {
    const [triedUser, setTriedUser] = useState(false)
    const [foundUser, setFoundUser] = useState(false)
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { selectedTeam } = useSelector(state => state.app)

    const dispatch = useDispatch();

    const addBroker = async () => {
        if (email.trim() !== '') {
            setIsLoading(true);
            const newBroker = { email, name, phone };
            request(`teams/${selectedTeam.id}/members/`, 'POST', newBroker).then((res) => {
                setEmail('');
                setName('');
                setPhone('');
                setTriedUser(false);
                setIsLoading(false);
                setIsOpen(false);
                dispatch(addInvite(res.content))
            });
        }
    };

    const tryBroker = () => {
        if (email.trim() !== '') {
            request(`users/${email}/`, 'GET').then(res => {
                if (res.success) {
                    setEmail(res.content.email);
                    setName(res.content.name);
                    setFoundUser(true)
                } else {
                    setFoundUser(false)
                }
                setTriedUser(true);
            });
        }
    }

    if (!isOpen) {
        return <Button className={"HeaderActionButton"} variant={'contained'} onClick={() => setIsOpen(true)}>
            Adicionar membro
        </Button>
    }

    if (!triedUser) {
        return <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                <h2>Adicionar Membro</h2>
                <h3>Digite o email para localizar o membro</h3>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
                { isLoading ? <ThreeDots
                    color={"#3F1416"}
                /> : <Button variant="contained" onClick={tryBroker}>Buscar Membro</Button>}
            </Box>
        </Modal>
    }

    if (foundUser) {
        return <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                <h2>Adicionar Membro</h2>
                <h3>O convite será enviado para o seguinte membro:</h3>
                <TextField sx={{opacity: 1}} label="Nome" value={name} fullWidth margin="normal" disabled/>
                <TextField sx={{opacity: 1}} label="Email" value={email} fullWidth margin="normal" disabled/>
                { isLoading ? <ThreeDots
                    color={"#3F1416"}
                /> : <Button variant="contained" onClick={addBroker}>Convidar Membro</Button>}
            </Box>
        </Modal>
    }

    return <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                <h2>Adicionar Membro</h2>
                <h3>Membro não encontrado, preencha as informações para convidá-lo:</h3>
                <TextField label="Nome" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
                <TextField label="Telefone" value={phone} onChange={(e) => setPhone(e.target.value)} fullWidth margin="normal" />
                { isLoading ? <ThreeDots
                    color={"#3F1416"}
                /> : <Button variant="contained" onClick={addBroker}>Adicionar Membro</Button>}
            </Box>
        </Modal>
}

export default CreateMember