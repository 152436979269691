import {useEffect, useState} from "react";
import LogoImage from "../../img/logo-master-new.png";
import {Alert, Button, FormLabel, Input, InputAdornment} from "@mui/material";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import "../../Styles/LoginForm.scss";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../Services/Auth/authSlice";
import request from "../../Services/Request";
import FormErrorMessage from "../../Components/FormErrorMessage";
import { useNavigate } from "react-router-dom";
import LoginBackground from "../../img/login-background.jpg"
import Logo from "../../img/Logo.webp";
import {FaWhatsapp, FaWhatsappSquare} from "react-icons/fa";
import {FaEnvelope} from "react-icons/fa6";
import {setAlert} from "../../AppSlice";
import InputMask from "react-input-mask";
import {getIsMobile} from "../../Services/helpers";

function Login({onLogin}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [formErrors, setFormErrors] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestingPasswordRecovery, setIsRequestingPasswordRecovery] = useState(false);
    const [phone, setPhone] = useState("");

    const {isRegistering, isRecovering} = useSelector(state => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('register')) {
            request(`invites/${urlParams.get('register')}/`, "GET", null, true).then(res => {
                if (res.success) {
                    setName(res.content.user.name)
                    setEmail(res.content.user.email)
                }
            })
        }
    }, []);

    const validateForm = () => {
        const errors = {};

        if (!email) {
            errors.email = "Email é obrigatório";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            errors.email = "Email inválido";
        }

        if (!password && !isRequestingPasswordRecovery) {
            errors.password = "Senha é obrigatória";
        }

        if (isRegistering) {
            if (!name) {
                errors.name = "Nome é obrigatório";
            }
            if (!passwordConfirmation) {
                errors.passwordConfirmation = "Confirmação de Senha é obrigatória";
            } else if (passwordConfirmation !== password) {
                errors.passwordConfirmation = "Senhas divergentes";
            }
        }
        return errors;
    }

    const handleLogin = (receivedEmail=null) => {
        const requestData = {
            email: receivedEmail ? receivedEmail : email,
            password
        }

        request("authentication/login/", "POST", requestData)
            .then((res) => {
                setIsLoading(false);
                if (res.access) {
                    dispatch(login(res.access))
                    if(isRegistering || isRecovering) return window.location.replace('https://masterprod.com.br/');
                    return navigate("/")
                } else {
                    setFormMessage("Login falhou. Verifique suas credenciais.");
                    setLoginSuccess(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setFormMessage("Ocorreu um erro no servidor.");
                setLoginSuccess(false);
            });
    }

    const handleRegister = () => {
        const requestData = {
            status: "accepted",
            email,
            password,
            name,
            phone
        }

        const urlParams = new URLSearchParams(window.location.search);

        request(`invites/${urlParams.get('register')}/`, "PUT", requestData, true)
            .then((res) => {
                setIsLoading(false);
                if (res.success) {
                    return handleLogin();
                }
                setFormMessage("Login falhou. Verifique suas credenciais.");
                return setLoginSuccess(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setFormMessage("Ocorreu um erro no servidor.");
                setLoginSuccess(false);
            });
    }

    const handleRequestPasswordRecovery = () => {
        request(`user/recovery/${email}/`, "POST", {}, true).then((res) => {
            if (res.success) {
                dispatch(setAlert({message: "Verifique seu email para instruções de recuperação da senha.", severity: "success"}))
            } else {
                dispatch(setAlert({message: "Erro ao solicitar recuperação de senha.", severity: "error"}))
            }
            setIsLoading(false)
        })
    }

    const handleRecoverPassword = () => {
        console.log("RECOVERING PASSWORD")

        if (password !== passwordConfirmation) {
            return dispatch(setAlert({message: "Senha e confirmação não conferem.", severity: "warning"}))
        }

        console.log("NOT DIFFERENT")

        const urlParams = new URLSearchParams(window.location.search);

        const requestData = {
            password: password,
            token: urlParams.get('recovery')
        }

        console.log("REQUESTING")

        request(`user/recovery/`, "PUT", requestData, true).then((res) => {
            setIsLoading(false)
            if (res.success) {
                dispatch(setAlert({message: "Senha recuperada com sucesso.", severity: "success"}))
                return handleLogin(res.email)
            } else {
                dispatch(setAlert({message: "Erro ao recuperar senha.", severity: "error"}))
            }
        })
    }

    const handleSubmitForm = () => {
        setIsLoading(true);

        if (isRecovering) {
            return handleRecoverPassword()
        }

        if (isRequestingPasswordRecovery) {
            return handleRequestPasswordRecovery()

        }

        const errors = validateForm();

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return setIsLoading(false)
        }

        setFormErrors(false)

        if (isRegistering) {
            return handleRegister();
        }
        return handleLogin();
    };

    const isMobile = getIsMobile()

    console.log("IS REGIUST", isRegistering, isRecovering)

    return (
        <div className={"Background"}>
            <div className={"BackgroundCover"}></div>
            <img src={LoginBackground} alt={"aaa"} className={"BackgroundImage"}/>
            <div className={"FormContainer"}>
                { !(isRegistering && isMobile) && <div className={"LogoContainer"}>
                    <img src={Logo} alt={"Logo"}/>
                    { !isRegistering && <h4>Gerencie sua produção<br/>de modo fácil</h4>}
                </div> }
                <div className={"InputsContainer"} style={{height: isRegistering ? '80%' : null}}>
                    {isRegistering && <div className={"FieldContainer"}>
                        <FormLabel htmlFor={"name"}>Nome</FormLabel>
                        <Input
                            id={"name"}
                            style={{width: "100%", height: "32px", background: "white", borderRadius: "5px", padding: 5}}
                            label={"Nome"}
                            variant="standard"
                            type="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormErrorMessage message={formErrors.name}/>
                    </div>}
                    { !isRecovering && <div className={"FieldContainer"}>
                        <FormLabel htmlFor={"email"}>E-mail</FormLabel>
                        <Input
                            id={"email"}
                            style={{
                                width: "100%",
                                height: "32px",
                                background: "white",
                                borderRadius: "5px",
                                padding: 5
                            }}
                            label={"Email"}
                            variant="standard"
                            type="email"
                            value={email}
                            disabled={isRegistering}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormErrorMessage message={formErrors.email}/>
                    </div>}
                    { isRegistering && <div className={"FieldContainer"}>
                        <FormLabel htmlFor={"phone"}>Telefone</FormLabel>
                        <InputMask
                            id={"phone"}
                            name="phone"
                            placeholder={"Telefone"}
                            mask={"(99) 9 9999-9999"}
                            value={phone}
                            style={{
                                width: "96%",
                                height: "32px",
                                background: "white",
                                borderRadius: "5px",
                                padding: 5
                            }}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <FormErrorMessage message={formErrors.email}/>
                    </div>}
                    { !isRequestingPasswordRecovery && <div className={"FieldContainer"}>
                        <FormLabel htmlFor={"password"}>{isRegistering ? "Senha" : "Senha"}</FormLabel>
                        <Input
                            id={"password"}
                            style={{
                                width: "100%",
                                height: "32px",
                                background: "white",
                                borderRadius: "5px",
                                padding: 5
                            }}
                            label="Senha"
                            variant="standard"
                            type={!showPassword ? "password" : "text"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                password ? (
                                    <InputAdornment
                                        position="end"
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        {!showPassword ? <AiFillEye size={20}/> : <AiFillEyeInvisible size={20}/>}
                                    </InputAdornment>
                                ) : null
                            }
                        />
                        <FormErrorMessage message={formErrors.password}/>
                    </div>}
                    {(isRegistering || isRecovering) && <div className={"FieldContainer"}>
                        <FormLabel htmlFor={"passwordConfirmation"}>Confirmação de senha</FormLabel>
                        <Input
                            id={"passwordConfirmation"}
                            style={{
                                width: "100%",
                                height: "32px",
                                background: "white",
                                borderRadius: "5px",
                                padding: 5
                            }}
                            label="Senha"
                            variant="standard"
                            type={!showPassword ? "password" : "text"}
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    {!showPassword ? <AiFillEye size={20}/> : <AiFillEyeInvisible size={20}/>}
                                </InputAdornment>
                            }
                        />
                        <FormErrorMessage message={formErrors.passwordConfirmation}/>
                    </div>}
                </div>
                <div className={"ButtonsContainer"}>
                    <Button
                        type={"submit"}
                        className={"button-login"}
                        variant="contained"
                        style={{cursor: isLoading ? "default" : "pointer",}}
                        disabled={isLoading}
                        onClick={handleSubmitForm}
                    >
                        {isLoading ? "Aguarde..." : isRegistering ? "Cadastrar" : isRequestingPasswordRecovery || isRecovering ? "Recuperar Senha" : "Entrar"}
                    </Button>
                </div>
                {/*{formMessage && <Alert>*/}
                {/*    {formMessage}*/}
                {/*</Alert>}*/}
                { !isRegistering && !isRecovering && <div className={"ForgotPassword"} onClick={() => setIsRequestingPasswordRecovery(!isRequestingPasswordRecovery)}>{ isRequestingPasswordRecovery ? 'Voltar' : 'Esqueci minha senha'}</div>}
            </div>
        </div>
    );
}

export default Login;
