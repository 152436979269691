// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonComponent {
  border: none;
  background-color: #0B3F3C;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; }
  .ButtonComponent:hover {
    cursor: pointer;
    background-color: #2c5552;
    transition: background-color 150ms ease; }
`, "",{"version":3,"sources":["webpack://./src/Components/Button/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,EAAA;EARd;IAWI,eAAe;IACf,yBAAyB;IACzB,uCAAuC,EAAA","sourcesContent":[".ButtonComponent {\n  border: none;\n  background-color: #0B3F3C;\n  border-radius: 5px;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 20px;\n\n  &:hover {\n    cursor: pointer;\n    background-color: #2c5552;\n    transition: background-color 150ms ease;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
