// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarFooterContainer {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 50px;
  width: 80%; }

.SidebarFooterItem {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  height: 50px;
  border-radius: 6.25px;
  cursor: pointer;
  background-color: #3F2527;
  width: 75%; }
  .SidebarFooterItemSmall {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 6.25px;
    cursor: pointer;
    background-color: #3F2527;
    width: 20%; }

.SidebarFooter > span {
  margin-left: 15px; }

.SidebarFooter:hover {
  background-color: #253F3D; }
`, "",{"version":3,"sources":["webpack://./src/Components/SidebarMain/SidebarFooter.scss"],"names":[],"mappings":"AACE;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,UAAU,EAAA;;AAGZ;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,yBAAyB;EACzB,UAAU,EAAA;EAEV;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,UAAU,EAAA;;AA9BhB;EAmCI,iBAAiB,EAAA;;AAnCrB;EAuCI,yBAAyB,EAAA","sourcesContent":[".SidebarFooter {\n  &Container {\n    display: flex;\n    align-self: center;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    height: 50px;\n    width: 80%;\n  }\n\n  &Item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    column-gap: 20px;\n    height: 50px;\n    border-radius: 6.25px;\n    cursor: pointer;\n    background-color: #3F2527;\n    width: 75%;\n\n    &Small {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 100%;\n      border-radius: 6.25px;\n      cursor: pointer;\n      background-color: #3F2527;\n      width: 20%;\n    }\n  }\n\n  >span {\n    margin-left: 15px;\n  }\n\n  &:hover {\n    background-color: #253F3D;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
