import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    goals: false
}

export const goalsSlice = createSlice({
    name: 'goals',
    initialState,
    reducers: {
        setGoals: (state, action) => {
            state.goals = action.payload
        },
        addGoal: (state, action) => {
            state.goals = [...state.goals, action.payload]
        },
        removeGoal: (state, action) => {
            state.goals = state.goals.filter(goal => goal.id !== action.payload)
        },
        updateGoal: (state, action) => {
            const sectorIndex = state.goals.findIndex(goal => goal.id === action.payload.id)
            if (sectorIndex > -1) {
                state.goals[sectorIndex] = action.payload
            }
        },
        resetGoals: () => {
            return {...initialState}
        }
    }
})

export const {
    setGoals,
    addGoal,
    removeGoal,
    updateGoal,
    resetGoals
} = goalsSlice.actions

export default goalsSlice.reducer;
