import Modal from "../../Components/Modal";
import {FaTrash} from "react-icons/fa6";
import React from "react";

const RemoveLeadModal = ({ onConfirm }) => {
    return <Modal
        title="Remover Produto"
        content={<div><h3>Tem certeza que deseja remover essa prospecção?</h3></div>}
        onConfirm={onConfirm}
        buttonText={<FaTrash/>}
    />
}

export default RemoveLeadModal;