export default function TabContent(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            {value === index && children}
        </div>
    );
}