import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Services/Auth/authSlice'
import appReducer from './AppSlice'
import membersReducer from './Pages/Members/MembersSlice'
import productsReducer from './Pages/Products/ProductsSlice'
import sectorsReducer from './Pages/Sectors/SectorsSlice'
import goalsReducer from "./Pages/Goals/goalsSlice";
import leadsReducer from "./Pages/Leads/leadsSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        app: appReducer,
        members: membersReducer,
        products: productsReducer,
        sectors: sectorsReducer,
        goals: goalsReducer,
        leads: leadsReducer
    },
})