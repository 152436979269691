// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px; }
  .ProgressBarContainer .ProgressBarTotal {
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 20px;
    border-radius: 10px;
    cursor: default;
    overflow: hidden; }
    .ProgressBarContainer .ProgressBarTotal .ProgressBarFill {
      border-radius: 10px;
      height: 100%;
      background-color: #00A32E;
      transition: width 500ms ease-in-out; }
  .ProgressBarContainer .ProgressBarLabel {
    margin-left: 5px; }
`, "",{"version":3,"sources":["webpack://./src/Components/ProgressBar/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,YAAY,EAAA;EALd;IAQI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB,EAAA;IAhBpB;MAoBM,mBAAmB;MACnB,YAAY;MACZ,yBAAyB;MACzB,mCAAmC,EAAA;EAvBzC;IA4BI,gBAAgB,EAAA","sourcesContent":[".ProgressBarContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  height: 50px;\n\n  .ProgressBarTotal {\n    background-color: #D9D9D9;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    width: 50%;\n    height: 20px;\n    border-radius: 10px;\n    cursor: default;\n    overflow: hidden;\n\n\n    .ProgressBarFill {\n      border-radius: 10px;\n      height: 100%;\n      background-color: #00A32E;\n      transition: width 500ms ease-in-out;\n    }\n  }\n\n  .ProgressBarLabel {\n    margin-left: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
