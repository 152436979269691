import React from 'react';
import "./style.scss";
import DropdownSelector from "../DropdownSelector";

function Table(props) {
    const { columns, data, actions, itemClassName, noGap, compact, firstRowSticky, pagination } = props;

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(50);

    const totalPages = React.useMemo(() => Math.ceil(data.length / itemsPerPage), [data.length, itemsPerPage]);

    const currentData = React.useMemo(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = currentPage * itemsPerPage;
        return data.slice(start, end);
    }, [currentPage, data, itemsPerPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const generatePageNumbers = React.useMemo(() => {
        const maxPageNumbers = 5;
        const half = Math.floor(maxPageNumbers / 2);
        let start = Math.max(1, currentPage - half);
        let end = Math.min(totalPages, start + maxPageNumbers - 1);

        if (end - start + 1 < maxPageNumbers) {
            start = Math.max(1, end - maxPageNumbers + 1);
        }

        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    }, [currentPage, totalPages]);

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    return (
        <div className={"TableWrapper"}>
            {pagination && <div className={`TableControls${currentData.length < 10 ? " marginTop" : ""}`}>
                <div className={"ItemsPerPageSelector"}>
                    <DropdownSelector
                        id={"itemsPerPageSelector"}
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        options={[10, 20, 50, 100]}
                        fullWidth
                        compact
                    />
                </div>
                <div className="Pagination">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>
                    {generatePageNumbers.map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={currentPage === number ? "active" : ""}
                        >
                            {number}
                        </button>
                    ))}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Próxima
                    </button>
                </div>
            </div>}
            <div className={"TableContent"}>
                <table cellPadding={7} cellSpacing={0} className={`TableContainer`} style={noGap ? {rowGap: 0} : null}>
                    <thead className={`TableHeader${firstRowSticky ? ' FirstRowSticky' : ''}`}
                           style={noGap ? {borderRadius: 10, marginBottom: 10} : {borderRadius: 10}}>
                    {columns.map((column, index) => (
                        <th key={index} className={column.small ? "Small" : ""}>{column.name}</th>
                    ))}
                    {actions ? <th>Ações</th> : null}
                    </thead>
                    <tbody>
                    {currentData.map((item, i) => {
                        const isSelected = selectedRow === i;
                        return (
                            <tr
                                key={i}
                                style={item.customStyle ? item.customStyle : {}}
                                className={`TableItem ${itemClassName} ${compact ? 'compact' : ''} ${firstRowSticky ? ' FirstRowSticky' : ''} ${isSelected ? ' Selected' : ''}`}
                                onClick={() => setSelectedRow(isSelected ? null : i)}
                            >
                                {columns.map((column, index) => (
                                    <td key={index} className={column.small ? "Small" : ""}>{item[column.key]}</td>
                                ))}
                                {actions && <td className={"ActionsContainer"}>
                                    {actions.map((action, index) => (
                                        <div key={index} className={"ActionIcon"}
                                             onClick={() => action.onClick(item.id)}>
                                            {action.icon}
                                        </div>
                                    ))}
                                </td>}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Table;
