import React, {useEffect, useState} from 'react';
import {
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import './goals.scss';
import {getProgress} from '../../Services/helpers';
import {useSelector} from "react-redux";
import ProgressBar from "../../Components/ProgressBar";
import {currencyFormat} from "../../Services/helpers";
import GoalItem from "./goalItem";

const Goals = () => {

    const {products} = useSelector(state => state.products)
    const [goalsBySector, setGoalsBySector] = useState(false)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)

    const [selectedProduct, setSelectedProduct] = useState(products? products[0] ? products[0].id : false : false )
    const {goals} = useSelector(state => state.goals)
    const {sectors} = useSelector(state => state.sectors)
    const {productions} = useSelector(state => state.app)

    useEffect(() => {
        if (!sectors || !goals) return
        const newGoalsBySector = {}
        sectors.forEach(sector => {
            const sectorGoals = goals.filter(goal => goal.month === selectedMonth && goal.year === selectedYear && goal.product === selectedProduct && goal.sector === sector.id);
            const goalsTotal = sectorGoals.reduce((acc, goal) => acc + Number(goal.amount), 0);
            const untilNow = productions
                .filter(production => Number(production.date.split("-")[0]) === selectedYear && Number(production.date.split("-")[1]) === selectedMonth && production.product === selectedProduct && production.sector === sector.id)
                .reduce((acc, production) => acc + Number(production.amount), 0)
            const remaining = goalsTotal - untilNow
            newGoalsBySector[sector.id] = {goals: sectorGoals, amount: goalsTotal, total: untilNow, remaining};
        })

        setGoalsBySector(newGoalsBySector);
    }, [goals, sectors, selectedYear, selectedMonth, selectedProduct]);

    const getTeamData = () => {
        if (!goalsBySector) return false
        let amount = 0;
        let total = 0;
        Object.values(goalsBySector).forEach((sector, i) => {
            amount += sector.amount;
            total += sector.total;
        })

        return {amount, total, remaining: amount - total}
    }

    useEffect(() => {
        if (!products) {
            setSelectedProduct(products[0])
        }
    }, [products]);

    console.log(getTeamData(), goalsBySector)

    return <div style={{
        height: '100%'
    }}>
        <div style={{display: "flex"}}>
            <div style={{width: 150}}>
                <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    label="Age"
                    onChange={(e) => setSelectedYear(e.target.value)}
                    fullWidth
                >
                    <MenuItem value={2020}>2020</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                </Select>
            </div>
            <div style={{width: 150, marginLeft: 15}}>
                <InputLabel id="demo-simple-select-label">Mês</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedMonth}
                    label="Age"
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    fullWidth
                >
                    <MenuItem value={1}>Janeiro</MenuItem>
                    <MenuItem value={2}>Fevereiro</MenuItem>
                    <MenuItem value={3}>Março</MenuItem>
                    <MenuItem value={4}>Abril</MenuItem>
                    <MenuItem value={5}>Maio</MenuItem>
                    <MenuItem value={6}>Junho</MenuItem>
                    <MenuItem value={7}>Julho</MenuItem>
                    <MenuItem value={8}>Agosto</MenuItem>
                    <MenuItem value={9}>Setembro</MenuItem>
                    <MenuItem value={10}>Outubro</MenuItem>
                    <MenuItem value={11}>Novembro</MenuItem>
                    <MenuItem value={12}>Dezembro</MenuItem>
                </Select>
            </div>
            <div style={{width: 150, marginLeft: 15}}>
                <InputLabel id="demo-simple-select-label">Produto</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProduct}
                    label="Produto"
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    fullWidth
                >
                    {
                        products && products.map(product => (
                            <MenuItem value={product.id}>{product.name}</MenuItem>
                        ))
                    }
                </Select>
            </div>
        </div>

        <div className={"Container"}>
            <div className={"RowContainer"}>
                {getTeamData() ? <div className={"Row"} style={{marginBottom: 20}}>
                    <div className={"RowContent"}>
                        <div className={"Titles"}>
                            <span>GERAL</span>
                            <span>{currencyFormat(getTeamData().amount)}</span>
                            <span>{currencyFormat(getTeamData().total)}</span>
                            <span
                                style={{color: getTeamData().remaining > 0 ? "red" : "green"}}>{currencyFormat(getTeamData().remaining)}</span>
                        </div>
                        <div className={"Progress"}>
                            <ProgressBar
                                completed={getProgress(getTeamData().amount, getTeamData().total)}
                            />
                        </div>
                    </div>
                </div> : null}
            </div>
            <div className={"Header"}>
                <span>Agência</span>
                <span>Objetivo</span>
                <span>Realizado</span>
                <span>Defasagem</span>
            </div>
            <div className={"RowContainer"} style={{height: '80%'}}>
                {
                    sectors && goalsBySector ? sectors.map((sector, i) => {
                        const sectorData = goalsBySector[sector.id]
                        return <GoalItem
                            sector={sector}
                            sectorData={sectorData}
                            selectedYear={selectedYear}
                            selectedMonth={selectedMonth}
                            selectedProduct={selectedProduct}
                        />
                    }) : null
                }
            </div>
        </div>
    </div>
};

export default Goals;
