import React from 'react';
import "./styles.scss";

function Index({children, onClick, style}) {
    return (
        <button
            className={"ButtonComponent"}
            onClick={onClick}
            style={style ? style : {}}
        >
            {children}
        </button>
    );
}

export default Index;
