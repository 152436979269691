import React, {useMemo, useState} from 'react';
import {
    CircularProgress
} from '@mui/material';
import request from "../../Services/Request";
import {useDispatch, useSelector} from "react-redux";
import {updateProduct} from "./ProductsSlice";
import {FaX} from "react-icons/fa6";
import Table from "../../Components/Table";
import {FaCheck} from "react-icons/fa";

const Products = () => {

    const dispatch = useDispatch()
    const { selectedTeam } = useSelector(state => state.app)
    const { products } = useSelector(state => state.products)

    const handleChangeProductStatus = async (id) => {
        request(`teams/${selectedTeam.id}/products/${id}/`, 'PUT').then(res => {
            if (res.success) {
                dispatch(updateProduct(res.content))
            }
        });
    };

    const tableColumns = [
        {
            name: "Nome",
            key: 'name'
        },
        {
            name: "Tipo de preenchimento",
            key: 'type'
        },
        {
            name: "Ativo",
            key: "isActive",
        }
    ]

    const tableActions = [
        {
            icon: <FaX color={"white"}/>,
            onClick: (id) => handleChangeProductStatus(id)
        }
    ]

    const tableData = useMemo(() => products && products.map(product => {
        return {
            id: product.id,
            name: product.name,
            type: product.type === "V" ? "Valor" : "Quantidade",
            isActive: product.isActive ? <FaCheck color={"green"} size={20}/> : <FaX color={"red"} size={20}/>
        }
    }), [products])

    return (
        <div style={{
            height: '100%',
            overflowY: 'auto'
        }}>
            {
                products ? <Table compact columns={tableColumns} data={tableData} actions={tableActions}/> : <CircularProgress/>
            }
        </div>
    );
};

export default Products;
