import request from "../Request";
import {useDispatch} from "react-redux";
import {resetAuth} from "./authSlice";
import {resetApp} from "../../AppSlice";
import {resetGoals} from "../../Pages/Goals/goalsSlice";
import {resetMembers} from "../../Pages/Members/MembersSlice";
import {resetProducts} from "../../Pages/Products/ProductsSlice";
import {resetSectors} from "../../Pages/Sectors/SectorsSlice";

export function isLoggedIn() {
    const token = localStorage.getItem("auth-token")
    return token !== null
}

export async function login(email, password) {
    return await request('login1/', "POST", {email: email, password: password}, true).then(r => {
        if (r.token) {
            localStorage.setItem("auth-token", r.token)
            return {success: true}
        } else {
            return {success: false}
        }
    })
}

export function logout() {
    localStorage.removeItem("auth-token")
}