// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductionDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 15px;
  height: 100%; }
  .ProductionDetailsTitle {
    margin: 0; }
  .ProductionDetailsContent {
    max-height: 90%; }
  .ProductionDetailsFilters {
    display: flex;
    flex-direction: row; }
    .ProductionDetailsFilters.Column {
      width: 100%;
      align-items: center;
      flex-direction: column;
      row-gap: 15px; }

@media (max-width: 480px) {
  .ProductionDetails {
    width: 97%; } }
`, "",{"version":3,"sources":["webpack://./src/Pages/ProductionDetails/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,YAAY,EAAA;EAEZ;IACE,SAAS,EAAA;EAGX;IACE,eAAe,EAAA;EAGjB;IACE,aAAa;IACb,mBAAmB,EAAA;IAFpB;MAKG,WAAW;MACX,mBAAmB;MACnB,sBAAsB;MACtB,aAAa,EAAA;;AAKnB;EACE;IACE,UAAU,EAAA,EACX","sourcesContent":[".ProductionDetails {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  row-gap: 15px;\n  height: 100%;\n\n  &Title {\n    margin: 0;\n  }\n\n  &Content {\n    max-height: 90%;\n  }\n\n  &Filters {\n    display: flex;\n    flex-direction: row;\n\n    &.Column {\n      width: 100%;\n      align-items: center;\n      flex-direction: column;\n      row-gap: 15px;\n    }\n  }\n}\n\n@media (max-width: 480px) {\n  .ProductionDetails {\n    width: 97%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
