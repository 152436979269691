import React from 'react';
import {
    Button,
    Paper,
    CircularProgress, Tooltip, IconButton
} from '@mui/material';
import request from "../../Services/Request";
import {useSelector} from "react-redux";
import CreateMember from "./CreateMember";
import Table from "../../Components/Table";
import {FaClockRotateLeft, FaList, FaTrash} from "react-icons/fa6";
import './style.scss';
import {useNavigate} from "react-router-dom";

const Members = () => {
    const { selectedTeam } = useSelector(state => state.app)
    const { members, invites } = useSelector(state => state.members)
    const navigate = useNavigate();

    const deleteMember = async (id) => {
        await request(`teams/${selectedTeam.id}/members/${id}/`, 'DELETE');
    };

    const cancelInvite = (id) => {
        const requestData = {
            status: "canceled"
        }
        request(`invites/${id}/`, 'PUT', requestData)
    }

    const membersActions = [
        {
            icon: <Tooltip title={"Ver histórico do corretor"}>
                <IconButton>
                    <FaClockRotateLeft color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            onClick: id => navigate(`/history/?type=member&id=${id}`)
        },
        {
            'icon': <Tooltip title={"Excluir Corretor"}>
                <IconButton>
                    <FaTrash color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            'onClick': deleteMember
        }
    ]

    const invitesActions = [
        {
            'icon': <FaTrash color={"white"}/>,
            'onClick': cancelInvite
        }
    ]

    const tableColumns = [
        {
            'name': 'Nome',
            'key': 'name'
        },
        {
            'name': 'Email',
            'key': 'email'
        },
        {
            'name': 'Telefone',
            'key': 'phone'
        }
    ]

    return (
        <div className='MembersTables'>

            <div className={"MembersTablesSection"} style={{height: invites && invites.filter(invite => invite.status === "P").length === 0 ? '100%' : undefined}}>
                <h2>Membros da equipe</h2>

                {
                    members ? <Table compact columns={tableColumns} actions={membersActions} data={members}/>
                        : <CircularProgress/>
                }
            </div>
            {
                invites && invites.filter(invite => invite.status === "P").length > 0 && <div className={"MembersTablesSection"}>
                    <h2>Convites Pendentes</h2>

                    {
                        invites ? <Table compact columns={tableColumns} actions={invitesActions} data={invites.filter(invite => invite.status === "P").map(invite => {
                                return {
                                    name: invite.user.name,
                                    email: invite.user.email,
                                    phone: invite.user.phone
                                }
                            })}/>
                            : <CircularProgress/>
                    }
                </div>
            }
        </div>
    );
};

export default Members;
