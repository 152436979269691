// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateLead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 15px; }
  .CreateLead .Fields {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 5px; }
    .CreateLead .Fields .Products .Area {
      min-height: 150px;
      width: 100%;
      background-color: #d3d3d3;
      border-radius: 5px; }
      .CreateLead .Fields .Products .Area .Item {
        width: 100%;
        height: 50px;
        background-color: lightgrey; }

.Leads {
  width: 100%;
  height: 100%; }
  .Leads .Title {
    margin: 0; }
  .Leads .Content {
    width: 95%;
    height: 90%; }
  .Leads .Filters {
    display: flex;
    flex-direction: row;
    column-gap: 15px; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Leads/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa,EAAA;EALf;IAQI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,YAAY,EAAA;IAXhB;MAeQ,iBAAiB;MACjB,WAAW;MACX,yBAAyB;MACzB,kBAAkB,EAAA;MAlB1B;QAqBU,WAAW;QACX,YAAY;QACZ,2BAA2B,EAAA;;AAOrC;EACE,WAAW;EACX,YAAY,EAAA;EAFd;IAII,SAAS,EAAA;EAJb;IAOI,UAAU;IACV,WAAW,EAAA;EARf;IAYI,aAAa;IACb,mBAAmB;IACnB,gBAAgB,EAAA","sourcesContent":[".CreateLead {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  row-gap: 15px;\n\n  .Fields {\n    width: 70%;\n    display: flex;\n    flex-direction: column;\n    row-gap: 5px;\n\n    .Products {\n      .Area {\n        min-height: 150px;\n        width: 100%;\n        background-color: #d3d3d3;\n        border-radius: 5px;\n\n        .Item {\n          width: 100%;\n          height: 50px;\n          background-color: lightgrey;\n        }\n      }\n    }\n  }\n}\n\n.Leads {\n  width: 100%;\n  height: 100%;\n  .Title {\n    margin: 0;\n  }\n  .Content {\n    width: 95%;\n    height: 90%;\n  }\n\n  .Filters {\n    display: flex;\n    flex-direction: row;\n    column-gap: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
