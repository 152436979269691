// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100dvw;
  height: 50px;
  background-color: white; }

.HeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.SideMenuToggle {
  display: flex;
  justify-content: center;
  width: 50px;
  cursor: pointer; }

.HeaderSideMenu {
  transition: width 300ms; }

.HeaderActionButton {
  max-height: 30px;
  max-width: 80px !important;
  font-size: 10px !important;
  background-color: #0B3F3C !important; }

.PageTitle {
  font-size: x-small; }
`, "",{"version":3,"sources":["webpack://./src/Styles/Header.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,YAAY;EACZ,uBAAuB,EAAA;;AAG3B;EACI,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;;AAGvB;EACI,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,eAAe,EAAA;;AAGnB;EACI,uBAAuB,EAAA;;AAG3B;EACI,gBAAgB;EAChB,0BAA0B;EAC1B,0BAA0B;EAC1B,oCAAoC,EAAA;;AAGxC;EACI,kBAAkB,EAAA","sourcesContent":["#Header {\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 100dvw;\n    height: 50px;\n    background-color: white;\n}\n\n.HeaderContent {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.SideMenuToggle {\n    display: flex;\n    justify-content: center;\n    width: 50px;\n    cursor: pointer;\n}\n\n.HeaderSideMenu {\n    transition: width 300ms;\n}\n\n.HeaderActionButton {\n    max-height: 30px;\n    max-width: 80px !important;\n    font-size: 10px !important;\n    background-color: #0B3F3C !important;\n}\n\n.PageTitle {\n    font-size: x-small;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
