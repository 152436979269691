import React, {useState} from 'react';
import {Button, MenuItem, Modal, TextField} from "@mui/material";
import {Box} from "@mui/system";
import request from "../../Services/Request";
import {addProduct} from "./ProductsSlice";
import {useDispatch, useSelector} from "react-redux";

function CreateProduct(props) {
    const [productName, setProductName] = useState('');
    const [productType, setProductType] = useState('V');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {selectedTeam} = useSelector(state => state.app)

    const dispatch = useDispatch();

    const createProduct = async () => {
        if (productName.trim() !== '') {
            const newProduct = { name: productName, type: productType };
            request(`teams/${selectedTeam.id}/products/`, 'POST', newProduct).then(res => {
                if (res.success) {
                    dispatch(addProduct(res.content))
                }
                setProductName('');
                setProductType('V');
                setIsModalOpen(false);
            });
        }

    };

    return (
        <>
            <Button className={"HeaderActionButton"} variant="contained" onClick={() => setIsModalOpen(true)}>Adicionar Produto</Button>
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                    <h2>Adicionar Produto</h2>
                    <TextField label="Nome do Produto" value={productName} onChange={(e) => setProductName(e.target.value)} fullWidth margin="normal" />
                    <TextField select label="Tipo de Preenchimento" value={productType} onChange={(e) => setProductType(e.target.value)} fullWidth margin="normal">
                        <MenuItem value="V">Valor</MenuItem>
                        <MenuItem value="Q">Quantidade</MenuItem>
                    </TextField>
                    <Button variant="contained" onClick={createProduct}>Adicionar</Button>
                </Box>
            </Modal>
        </>
    );
}

export default CreateProduct;