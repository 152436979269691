import React, { useState } from 'react';
import "./checkout.scss";
import CheckoutRegistration from "./CheckoutRegistration";
import CheckoutPayment from "./CheckoutPayment";
import request from "../../Services/Request";
import {useNavigate} from "react-router-dom";
import {login} from "../../Services/Auth/authSlice";
import {useDispatch} from "react-redux";
import {setAlert} from "../../AppSlice";

function Checkout(props) {

    const [step, setStep] = useState(1);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleGoBack = () => {
        switch (step) {
            case 1:
                return navigate("/")
            case 2:
                return setStep(1)
        }
    }

    const handleContinue = (data) => {
        if (step === 1) {
            const requestData = {
                address: {
                    zip: data.zip,
                    street: data.address,
                    number: data.addressNumber,
                    complement: data.addressComplement,
                    city: data.addressCity,
                    state: data.addressState
                },
                user: {
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    password: data.password
                }
            }
            request("register/", "POST", requestData, true).then(async(res) => {
                if (res.success) {
                    const loginData = await request("authentication/login/", "POST", {email: data.email, password: data.password})
                    if (loginData.access) {
                        dispatch(login(loginData.access))
                        setStep(2)
                    } else {
                        dispatch(setAlert({severity: "error", message: "Erro ao criar conta, favor entrar em contato com o suporte."}))
                    }
                } else {
                    console.log("ERRO")
                    dispatch(setAlert({severity: "error", message: "Erro ao criar conta, favor entrar em contato com o suporte."}))
                }
            }).catch(err => {
                dispatch(setAlert({severity: "error", message: "Erro ao criar conta, favor entrar em contato com o suporte."}))
            })
        }
    }

    switch (step) {
        case 1:
            return <CheckoutRegistration handleGoBack={handleGoBack} handleContinue={handleContinue}/>
        case 2:
            return <CheckoutPayment/>
    }
}

export default Checkout;
