// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormErrorMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
  width: auto;
  border-radius: 10px;
  color: red; }
  .FormErrorMessage.active {
    background-color: #f0f0f0;
    max-height: 25px; }
`, "",{"version":3,"sources":["webpack://./src/Components/FormErrorMessage/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,UAAU,EAAA;EARZ;IAWI,yBAAyB;IACzB,gBAAgB,EAAA","sourcesContent":[".FormErrorMessage {\n  display: flex;\n  gap: 5px;\n  align-items: center;\n  padding: 5px;\n  margin: 5px 0;\n  width: auto;\n  border-radius: 10px;\n  color: red;\n\n  &.active {\n    background-color: #f0f0f0;\n    max-height: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
