import React from 'react';
import "./styles.scss";
import {FaTriangleExclamation} from "react-icons/fa6";

function FormErrorMessage({message}) {
    return (
        <div className={`FormErrorMessage${message ? ' active' : ''}`}>
            {
                message && <>
                    <FaTriangleExclamation/>
                    <p>{message}</p>
                </>
            }
        </div>
    )
}

export default FormErrorMessage;