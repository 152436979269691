// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sector {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box; }
  .SectorTitle {
    height: 10%;
    font-size: 18px;
    font-weight: bold; }
  .SectorContainer {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%; }
  .SectorContent {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 10px 10px 10px;
    border: 1px solid #f0f0f0; }

.Mui-selected {
  background-color: white !important;
  color: black !important;
  border-top: 2px solid lightblue !important; }

.MuiTabs-indicator {
  display: none; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Sectors/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB,EAAA;EAEtB;IACE,WAAW;IACX,eAAe;IACf,iBAAiB,EAAA;EAGnB;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,WAAW,EAAA;EAGb;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,+BAA+B;IAC/B,yBAAyB,EAAA;;AAK7B;EACE,kCAAkC;EAClC,uBAAuB;EAEvB,0CAA0C,EAAA;;AAI5C;EACE,aAAa,EAAA","sourcesContent":[".Sector {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  padding: 20px;\n  box-sizing: border-box;\n\n  &Title {\n    height: 10%;\n    font-size: 18px;\n    font-weight: bold;\n  }\n\n  &Container {\n    display: flex;\n    flex-direction: column;\n    height: 90%;\n    width: 100%;\n  }\n\n  &Content {\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    box-sizing: border-box;\n    background-color: white;\n    border-radius: 0 10px 10px 10px;\n    border: 1px solid #f0f0f0;\n  }\n}\n\n\n.Mui-selected {\n  background-color: white !important;\n  color: black !important;\n  //border: none !important;\n  border-top: 2px solid lightblue !important;\n}\n\n\n.MuiTabs-indicator {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
