// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible; }

.ArrowContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  top: 50px;
  z-index: 99999;
  background-color: #253F3D;
  border-radius: 50%;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: left 150ms linear; }

.SidebarBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh; }
`, "",{"version":3,"sources":["webpack://./src/Components/SidebarMain/Sidebar.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAA;;AAGnB;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;EACT,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,YAAY;EAEZ,6BAA6B,EAAA;;AAG/B;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,aAAa;EACb,cAAc,EAAA","sourcesContent":[".SidebarContainer {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: visible;\n}\n\n.ArrowContainer {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-self: flex-end;\n  top: 50px;\n  z-index: 99999;\n  background-color: #253F3D;\n  border-radius: 50%;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n\n  transition: left 150ms linear;\n}\n\n.SidebarBackdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100dvw;\n  height: 100dvh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
