import './Styles/App.scss';
import SidebarMain from "./Components/SidebarMain";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import "./Components/SidebarMain/Sidebar.scss"
import request from "./Services/Request";
import {
    setEmptyProductionFlags, setIsRecovering,
    setIsRegistering,
    setPath,
    setProductions,
    setSelectedTeam,
    setShowSidebar,
    setTeams,
    setTeamsEmptyProductionFlags,
    setUser,
    setUserRole
} from "./AppSlice";
import "./Pages/Dashboard/dashboard.scss"
import {CircularProgress} from "@mui/material";
import {setInvites, setMembers} from "./Pages/Members/MembersSlice";
import {setProducts} from "./Pages/Products/ProductsSlice";
import {setSectors} from "./Pages/Sectors/SectorsSlice";
import {logout} from "./Services/Auth/authSlice";
import IndexPage from "./Pages/IndexPage/IndexPage";
import {setGoals} from "./Pages/Goals/goalsSlice";
import {FaArrowLeft, FaBars} from "react-icons/fa6";
import {useProSidebar} from "react-pro-sidebar";
import Login from "./Pages/Login/Login";
import Checkout from "./Pages/Checkout/Checkout";
import Alert from "./Components/Alert";
import Header from "./Components/Header";
import {getIsMobile} from "./Services/helpers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/pt-br'

function App() {
    const [isAppLoaded, setIsAppLoaded] = useState(false)
    const [isFocused, setIsFocused] = useState(!document.hidden)

    const { isLoggedIn } = useSelector((state) => state.auth)
    const { alert, showSidebar, isRegistering, isRecovering, path, selectedTeam, user, userRole } = useSelector((state) => state.app)
    const dispatch = useDispatch()

    const location = useLocation();

    useEffect(() => {
        if (isFocused) loadApp()
    }, [isFocused])

    useEffect(() => {
        document.addEventListener("visibilitychange", () => {
            setIsFocused(!document.hidden)
        });
        if (location.pathname !== path) {
            dispatch(setPath(`/${location.pathname.split("/")[1]}`))
        }
    }, [location]);

    const { collapseSidebar, collapsed } = useProSidebar();

    const loadApp = async() => {
        if (selectedTeam) {
            if (userRole === "admin") loadInvites()
            if (userRole === "admin") loadMembers()
            if (userRole === "admin") loadEmptyFlags()
            loadEmptyFlag()
            loadProducts()
            loadSectors()
            loadGoals()
            loadProduction()
        }
    }

    const loadProduction = () => {
        request(`teams/${selectedTeam.id}/production/?type=${userRole}`, 'GET').then(res => {
            dispatch(setProductions(res))
        })
    }

    const loadGoals = () => {
        request(`teams/${selectedTeam.id}/goals/?type=${userRole}`, 'GET').then(res => {
            dispatch(setGoals(res))
        })
    }

    const loadEmptyFlags = () => {
        request(`teams/${selectedTeam.id}/production/empty/?type=admin`, 'GET').then(res => {
            dispatch(setTeamsEmptyProductionFlags(res.flags))
        })
    }

    const loadEmptyFlag = () => {
        request(`teams/${selectedTeam.id}/production/empty/?type=user`, 'GET').then(res => {
            dispatch(setEmptyProductionFlags(res.flags))
        })
    }

    const loadInvites = () => {
        return request(`invites/?team=${selectedTeam.id}`, 'GET').then(res => {
            dispatch(setInvites(res.content))
        })
    }

    const loadSectors = () => {
        request(`teams/${selectedTeam.id}/sectors/?type=${userRole}`, 'GET').then(res => {
            dispatch(setSectors(res.content.filter(sector => sector.is_active)))
        })
    }

    const loadMembers = () => {
        request(`teams/${selectedTeam.id}/members/`, 'GET').then(res => {
            dispatch(setMembers(res.content))
        })
    }

    const loadProducts = () => {
        request(`teams/${selectedTeam.id}/products/`, 'GET').then(res => {
            dispatch(setProducts(res.content))
        })
    }

    const loadUser = async() => {
        request("user/", "GET").then(res => {
            const teams = [...res.content.teams.admin, ...res.content.teams.user]
            const uniqueIds = new Set();
            const filteredArray = []
            teams.filter(obj => {
                if (uniqueIds.has(obj.id)) {
                    return false;
                } else {
                    uniqueIds.add(obj.id);
                    filteredArray.push(obj)
                    return true;
                }
            })
            dispatch(setTeams(filteredArray))
            dispatch(setSelectedTeam(filteredArray[0]))
            console.log("USUS", res.content.teams.admin.length)
            dispatch(setUserRole(res.content.teams.admin.length > 0 ? "admin" : "user"))
            dispatch(setUser(res.content))
        })
    }

    useEffect(() => {
        dispatch(setShowSidebar(!getIsMobile()))
        const urlParams = new URLSearchParams(window.location.search);
        const isRegistering = !!urlParams.get('register');
        const isRecovering = !!urlParams.get('recovery');
        dispatch(setIsRegistering(isRegistering))
        dispatch(setIsRecovering(isRecovering))
        if(isRegistering || isRecovering) {
            if (isLoggedIn) {
                dispatch(logout())
                window.location.reload()
            }
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            loadUser()
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (user) {
            loadApp().then(() => setIsAppLoaded(true))
        }
    }, [user, selectedTeam]);

    if (path === "/checkout") {
        return <div>
            <Alert severity={alert.severity} message={alert.message}/>
            <Checkout/>
        </div>
    }

    if (path === "/login" || isRegistering || isRecovering) {
        return <>
            <Alert severity={alert.severity} message={alert.message}/>
            <Login/>
            </>
    }

    if (!isLoggedIn) {
        return <IndexPage/>
    }

    const toggleSidebar = () => {
        dispatch(setShowSidebar(!showSidebar));
      };

    const isMobile = window.innerWidth < 500

    return (
        <div className="App">
            <Alert severity={alert.severity} message={alert.message}/>
             {showSidebar && <SidebarMain/>}
            {
                !isMobile && <div
                    className={"ArrowContainer"}
                    style={{left: collapsed ? 60 : 225, cursor: "pointer"}}
                    onClick={() => {
                        collapseSidebar()
                    }}
                >
                <FaArrowLeft
                    color={"white"}
                    style={{
                        transform: collapsed ? "rotate(180deg)" : "none"
                    }}
                />
                </div>
            }

            <div className={"PageContainer"}>
                {
                    isAppLoaded ? selectedTeam ? <>
                        <Header toggleSidebar={toggleSidebar}/>
                        <div className={"PageContent"} style={{
                            width: !collapsed && !isMobile ? "85dvw" : "95dvw",
                        }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
                                <Outlet/>
                            </LocalizationProvider>
                        </div>
                    </> : <h1>Nenhuma equipe</h1> : <CircularProgress/>
                }
            </div>
        </div>
    );
}

export default App;
