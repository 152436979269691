import React, {useState} from 'react';
import Table from "../../Components/Table";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {FaEdit, FaFilter} from "react-icons/fa";
import {FaTrash} from "react-icons/fa6";
import {
    Button,
    FormControl,
    FormGroup,
    FormLabel,
    IconButton,
    InputLabel,
    Tooltip
} from "@mui/material";
import "./style.scss";
import {Box} from "@mui/system";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DropdownSelector from "../../Components/DropdownSelector";
import {CurrencyInput} from "react-currency-mask";
import request from "../../Services/Request";
import {removeProduction, setAlert, updateProduction} from "../../AppSlice";
import {currencyFormat, getIsMobile} from "../../Services/helpers";
import Modal from "../../Components/Modal";

const tableColumns = [
    {name: 'Data', key: 'date'},
    {name: 'Produto', key: 'product'},
    {name: 'Valor', key: 'amount'},
    {name: 'Agência', key: 'sector'},
    {name: 'Corretor', key: 'member'}
]

function ProductionDetails(props) {

    const [productionToEdit, setProductionToEdit] = React.useState(null);
    const [productionToDelete, setProductionToDelete] = React.useState(null);
    const [selectedMonth, setSelectedMonth] = React.useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [selectedSector, setSelectedSector] = React.useState(null);
    const [selectedMember, setSelectedMember] = React.useState(null);

    const { productions } = useSelector((state) => state.app);
    const { products } = useSelector((state) => state.products);
    const { members } = useSelector((state) => state.members);
    const { sectors } = useSelector((state) => state.sectors);

    const isReady = productions && products && members && sectors

    const tableActions = [
        {
            icon: <Tooltip title={"Editar"}>
                <IconButton>
                    <FaEdit color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            onClick: (id) => setProductionToEdit(id)
        },
        {
            icon: <Tooltip title={"Remover"}>
                <IconButton>
                    <FaTrash color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            onClick: (id) => setProductionToDelete(id)
        }
    ]

    const sortedProductions = [...productions].filter(production => {
        const prodDate = dayjs(production.date);
        return (prodDate.month() + 1 === selectedMonth)
        && (prodDate.year() === selectedYear)
        && (!selectedProduct || production.product === selectedProduct)
        && (!selectedSector || production.sector === selectedSector)
        && (!selectedMember || production.member === selectedMember)
    }).sort((a,b) => {
        return dayjs(b.date) - dayjs(a.date);
    })

    return (
        <div className={"ProductionDetails"}>
            <h1 className={"ProductionDetailsTitle"}>Produções Registradas</h1>
            {
                !getIsMobile() ? <ProductionDetailsFilters
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    setSelectedMonth={setSelectedMonth}
                    setSelectedYear={setSelectedYear}
                    setSelectedProduct={setSelectedProduct}
                    setSelectedSector={setSelectedSector}
                    setSelectedMember={setSelectedMember}
                    selectedProduct={selectedProduct}
                    selectedMember={selectedMember}
                    products={products}
                    sectors={sectors}
                    members={members}
                /> : <Modal
                    title="Filtrar Produções"
                    buttonText={<div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        columnGap: 10
                    }}>
                        <FaFilter/>
                        <span>Filtrar</span>
                    </div>}
                    content={<ProductionDetailsFilters
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        setSelectedMonth={setSelectedMonth}
                        setSelectedYear={setSelectedYear}
                        setSelectedProduct={setSelectedProduct}
                        setSelectedSector={setSelectedSector}
                        setSelectedMember={setSelectedMember}
                        selectedProduct={selectedProduct}
                        selectedMember={selectedMember}
                        products={products}
                        sectors={sectors}
                        members={members}
                        column
                    />}
                    cancelText={"Voltar"}
                    noSubmit
                />
            }
            <div className={"ProductionDetailsContent"}>
                {
                    isReady && <Table
                        columns={tableColumns}
                        data={sortedProductions.map((production) => {
                            const product = products.find(product => product.id === production.product);
                            const sector = sectors.find(sector => sector.id === production.sector);
                            const member = members.find(member => member.id === production.member);
                            return {
                                id: production.id,
                                product: product.name,
                                member: member ? member.name : "Corretor Removido",
                                sector: sector ? sector.name : "Agência Removida",
                                amount: currencyFormat(Number(production.amount)),
                                date: dayjs(production.date).format("DD/MM/YYYY")
                            }
                        })}
                        actions={tableActions}
                        compact
                        pagination
                    />
                }
            </div>
            {productionToEdit && <EditProductionModal
                production={productions.find(production => production.id === productionToEdit)}
                onClose={() => setProductionToEdit(null)}
            />}
            {productionToDelete && <DeleteProductionModal
                production={productions.find(production => production.id === productionToDelete)}
                onClose={() => setProductionToDelete(null)}
            />
            }
        </div>
    );
}

const EditProductionModal = (props) => {
    const {production, onClose} = props

    const [newData, setNewData] = useState(production)

    const {selectedTeam} = useSelector((state) => state.app);
    const {products} = useSelector((state) => state.products);
    const {members} = useSelector((state) => state.members);
    const {sectors} = useSelector((state) => state.sectors);

    const dispatch = useDispatch();

    const handleChange = (property, value) => {
        setNewData({
            ...newData,
            [property]: value
        })
    }

    const handleSubmit = () => {
        request(`teams/${selectedTeam.id}/production/${production.id}/`, "PUT", newData).then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Produção alterada com sucesso", severity: "success"}))
                dispatch(updateProduction(res.production))
                onClose()
            } else {
                dispatch(setAlert({message: "Erro ao alterar produção", severity: "error"}))
            }
        })
    }

    if (!newData) return null

    return <Modal
        content={<FormGroup style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                rowGap: 15
            }}>
                <FormControl>
                    <FormLabel>Data</FormLabel>
                    <DatePicker
                        value={dayjs(newData.date)}
                        name={"date"}
                        onChange={(value) => handleChange('date', value.format('YYYY-MM-DD'))}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Produto</FormLabel>
                    <DropdownSelector
                        options={products.map(product => ({key: product.id, value: product.name}))}
                        value={products.find(product => product.id === newData.product).name}
                        fullWidth
                        containerStyle={{
                            height: 56,
                            borderRadius: 4
                        }}
                        innerStyle={{
                            borderRadius: 4
                        }}
                        name={"product"}
                        onChange={(value) => handleChange('product', value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Corretor</FormLabel>
                    <DropdownSelector
                        options={members.map(member => ({key: member.id, value: member.name}))}
                        value={members.find(member => member.id === newData.member).name}
                        fullWidth
                        containerStyle={{
                            height: 56,
                            borderRadius: 4
                        }}
                        innerStyle={{
                            borderRadius: 4
                        }}
                        name={"member"}
                        onChange={(value) => handleChange('member', value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Agência</FormLabel>
                    <DropdownSelector
                        options={sectors.map(sector => ({key: sector.id, value: sector.name}))}
                        value={sectors.find(sector => sector.id === newData.sector).name}
                        fullWidth
                        containerStyle={{
                            height: 56
                        }}
                        innerStyle={{
                            borderRadius: 4
                        }}
                        name={"sector"}
                        onChange={(value) => handleChange('sector', value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Valor</FormLabel>
                    <CurrencyInput
                        style={{
                            border: "1px solid lightgray",
                            backgroundColor: "white",
                            height: 35,
                            padding: 10,
                            cursor: "pointer",
                            color: "black",
                            borderRadius: 4,
                            width: '94%'
                        }}
                        name={"amount"}
                        value={newData.amount}
                        onChangeValue={(e, value) => handleChange('amount', value)}
                    />
                </FormControl>
            </FormGroup>}
        buttonText={<FaEdit/>}
        title={"Editar Produção"}
        buttonClass={"ActionIcon"}
        onConfirm={handleSubmit}
        onClose={onClose}
        open={!!production}
    />
}

const DeleteProductionModal = (props) => {
    const {production, onClose} = props

    const {selectedTeam} = useSelector(state => state.app)
    const {products} = useSelector(state => state.products)

    const dispatch = useDispatch()

    const product = production && products.find(product => product.id === production.product)

    const handleSubmit = () => {
        request(`teams/${selectedTeam.id}/production/${production.id}/`, "DELETE").then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Produção removida com sucesso", severity: "success"}))
                dispatch(removeProduction(production.id))
                onClose()
            } else {
                dispatch(setAlert({message: "Erro ao remover produção", severity: "error"}))
            }
        })
    }

    return <Modal
        content={<h3>Tem certeza que deseja remover a produção de {product?.name} ?</h3>}
        title={"Remover Produção"}
        open={!!production}
        onConfirm={handleSubmit}
    />
}

export default ProductionDetails;

function ProductionDetailsFilters(props) {
    const {
        selectedProduct,
        setSelectedProduct,
        selectedYear,
        setSelectedYear,
        selectedMonth,
        setSelectedMonth,
        selectedMember,
        setSelectedMember,
        selectedSector,
        setSelectedSector,
        products,
        members,
        sectors,
        column
    } = props
    return <div className={`ProductionDetailsFilters${column ? " Column" : ""}`}>
        <div style={{width: 150}}>
            <InputLabel id="demo-simple-select-label">Ano</InputLabel>
            <DropdownSelector
                id={"productionDetailsYear"}
                value={selectedYear}
                onChange={(value) => setSelectedYear(value)}
                options={[
                    2020, 2021, 2022, 2023, 2024
                ]}
            />
        </div>
        <div style={{width: 150, marginLeft: column ? 0 : 15}}>
            <InputLabel id="demo-simple-select-label">Mês</InputLabel>
            <DropdownSelector
                id={"productionDetailsMonth"}
                value={[
                    {key: 1, value: "Janeiro"},
                    {key: 2, value: "Fevereiro"},
                    {key: 3, value: "Março"},
                    {key: 4, value: "Abril"},
                    {key: 5, value: "Maio"},
                    {key: 6, value: "Junho"},
                    {key: 7, value: "Julho"},
                    {key: 8, value: "Agosto"},
                    {key: 9, value: "Setembro"},
                    {key: 10, value: "Outubro"},
                    {key: 11, value: "Novembro"},
                    {key: 12, value: "Dezembro"}
                ].find(month => month.key === selectedMonth).value}
                onChange={(value) => setSelectedMonth(value)}
                options={[
                    {key: 1, value: "Janeiro"},
                    {key: 2, value: "Fevereiro"},
                    {key: 3, value: "Março"},
                    {key: 4, value: "Abril"},
                    {key: 5, value: "Maio"},
                    {key: 6, value: "Junho"},
                    {key: 7, value: "Julho"},
                    {key: 8, value: "Agosto"},
                    {key: 9, value: "Setembro"},
                    {key: 10, value: "Outubro"},
                    {key: 11, value: "Novembro"},
                    {key: 12, value: "Dezembro"}
                ]}
            />
        </div>
        <div style={{width: 150, marginLeft: column ? 0 : 15}}>
            <InputLabel id="demo-simple-select-label">Produto</InputLabel>
            <DropdownSelector
                id={"productionDetailsProduct"}
                value={products && selectedProduct ? products.find(product => product.id === selectedProduct).name : "Todos"}
                onChange={(value) => setSelectedProduct(value)}
                options={
                    products ? [{
                        key: null,
                        value: "Todos"
                    }, ...products.map(product => (
                        {
                            key: product.id,
                            value: product.name
                        }
                    ))] : []
                }
            />
        </div>
        <div style={{width: 150, marginLeft: column ? 0 : 15}}>
            <InputLabel id="demo-simple-select-label">Agência</InputLabel>
            <DropdownSelector
                id={"productionDetailsSector"}
                value={sectors && selectedSector ? sectors.find(sector => sector.id === selectedSector).name : "Todos"}
                onChange={(value) => setSelectedSector(value)}
                options={
                    sectors ? [{
                        key: null,
                        value: "Todos"
                    }, ...sectors.map(sector => (
                        {
                            key: sector.id,
                            value: sector.name
                        }
                    ))] : []
                }
            />
        </div>
        <div style={{width: 150, marginLeft: column ? 0 : 15}}>
            <InputLabel id="demo-simple-select-label">Corretor</InputLabel>
            <DropdownSelector
                id={"productionDetailsMember"}
                value={members && selectedMember ? members.find(member => member.id === selectedMember).name : "Todos"}
                onChange={(value) => setSelectedMember(value)}
                options={
                    members ? [{
                        key: null,
                        value: "Todos"
                    }, ...members.map(member => (
                        {
                            key: member.id,
                            value: member.name
                        }
                    ))] : []
                }
            />
        </div>

    </div>
}
