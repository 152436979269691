import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    members: false,
    invites: false
}

export const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        setMembers: (state, action) => {
            state.members = action.payload
        },
        addMember: (state, action) => {
            state.members = [...state.members, action.payload]
        },
        removeMember: (state, action) => {
            state.members = state.members.filter(member => member.id !== action.payload)
        },
        setInvites: (state, action) => {
            state.invites = action.payload
        },
        addInvite: (state, action) => {
            state.invites = [...state.invites, action.payload]
        },
        removeInvite: (state, action) => {
            state.invites = state.invites.filter(invite => invite.id !== action.payload)
        },
        resetMembers: () => {
            return {...initialState}
        }
    }
})

export const {
    setMembers,
    addMember,
    removeMember,
    setInvites,
    addInvite,
    removeInvite,
    resetMembers
} = membersSlice.actions

export default membersSlice.reducer