// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MembersTables {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .MembersTablesSection {
    height: 40%; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Members/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,cAAc;EACd,WAAW;EACX,YAAY;EACZ,gBAAgB,EAAA;EAEhB;IACE,WAAW,EAAA","sourcesContent":[".MembersTables {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  row-gap: 100px;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n\n  &Section {\n    height: 40%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
