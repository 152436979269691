import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: false
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        },
        addProduct: (state, action) => {
            state.products = [...state.products, action.payload]
        },
        removeProduct: (state, action) => {
            state.products = state.products.filter(product => product.id !== action.payload)
        },
        updateProduct: (state, action) => {
            const productIndex = state.products.findIndex(product => product.id === action.payload.id)
            if (productIndex > -1) {
                state.products[productIndex] = action.payload
            }
        },
        resetProducts: () => {
            return {...initialState}
        }
    }
})

export const {
    setProducts,
    addProduct,
    removeProduct,
    updateProduct,
    resetProducts
} = productsSlice.actions

export default productsSlice.reducer