import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sectors: false
}

export const sectorsSlice = createSlice({
    name: 'sectors',
    initialState,
    reducers: {
        setSectors: (state, action) => {
            state.sectors = action.payload
        },
        addSector: (state, action) => {
            state.sectors = [...state.sectors, action.payload]
        },
        removeSector: (state, action) => {
            state.sectors = state.sectors.filter(sector => sector.id !== action.payload)
        },
        updateSector: (state, action) => {
            const sectorIndex = state.sectors.findIndex(sector => sector.id === action.payload.id)
            if (sectorIndex > -1) {
                state.sectors[sectorIndex] = action.payload
            }
        },
        resetSectors: () => {
            return {...initialState}
        }
    }
})

export const {
    setSectors,
    addSector,
    removeSector,
    updateSector,
    resetSectors
} = sectorsSlice.actions

export default sectorsSlice.reducer;
