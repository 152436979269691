// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  left: calc(25%);
  position: absolute;
  top: 2%;
  z-index: 5;
  width: 48%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; }
  .alert .icon {
    margin-right: 10px; }
  .alert .message {
    flex: 1 1; }
  .alert.info {
    background-color: #3498db; }
  .alert.error {
    background-color: #e74c3c; }
  .alert.alert {
    background-color: #f39c12; }
  .alert.success {
    background-color: #00A32E; }
  .alert.show {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; }
`, "",{"version":3,"sources":["webpack://./src/Components/Alert/style.scss"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,OAAO;EACP,UAAU;EACV,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,4BAA4B;EAC5B,gEAAgE,EAAA;EAhBlE;IAmBI,kBAAkB,EAAA;EAnBtB;IAuBI,SAAO,EAAA;EAvBX;IA2BI,yBAAyB,EAAA;EA3B7B;IA+BI,yBAAyB,EAAA;EA/B7B;IAmCI,yBAAyB,EAAA;EAnC7B;IAuCI,yBAAyB,EAAA;EAvC7B;IA2CI,UAAU;IACV,wBAAwB;IACxB,gEAAgE,EAAA","sourcesContent":["// Alert.scss\n\n.alert {\n  left: calc(25%);\n  position: absolute;\n  top: 2%;\n  z-index: 5;\n  width: 48%;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  padding: 10px 20px;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  color: white;\n  font-weight: bold;\n  opacity: 0; // Start with opacity 0 to fade in\n  transform: translateY(-100%); // Start above the screen\n  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Transition effect\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .message {\n    flex: 1;\n  }\n\n  &.info {\n    background-color: #3498db; // Blue color for info alerts\n  }\n\n  &.error {\n    background-color: #e74c3c; // Red color for error alerts\n  }\n\n  &.alert {\n    background-color: #f39c12; // Orange color for alert alerts\n  }\n\n  &.success {\n    background-color: #00A32E;\n  }\n\n  &.show {\n    opacity: 1; // Fade in effect\n    transform: translateY(0); // Slide down animation\n    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Transition effect\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
