import React, {useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import "./SectorMembers.scss";
import {FaArrowLeft, FaArrowRight, FaX} from "react-icons/fa6";
import SearchableDropdown from "../../Components/SearchableDropdown";
import {FaWindowClose} from "react-icons/fa";
import {IoMdCloseCircle} from "react-icons/io";
import {Button} from "@mui/material";
import request from "../../Services/Request";
import {updateSector} from "./SectorsSlice";
import {getMember} from "../../Services/helpers"

function getChanges(startingMembers, currentMembers) {
    const addedIds = currentMembers.filter(id => !startingMembers.includes(id));
    const removedIds = startingMembers.filter(id => !currentMembers.includes(id));

    return {
        added: addedIds,
        removed: removedIds
    };
}

function SectorMembers(props) {

    const { members } = useSelector(state => state.members)

    const [sectorMembersSelected, setSectorMembersSelected] = useState(props.sector.members)
    const [saveChanges, setSaveChanges] = useState(false)

    const dispatch = useDispatch()
    console.log("ANTES 1", props.sector)
    const membersOptions = useMemo(() => members.filter(member => !sectorMembersSelected.includes(member.id)).map(member => {
        return {
            id: member.id,
            name: `${member.name} - ${member.email}`,
        }
    }), [members, sectorMembersSelected])
    console.log("DEPOIS 1")

    const handleRemoveSectorMember = (memberId) => {
        setSectorMembersSelected(sectorMembersSelected.filter(member => member !== memberId))
    }

    const handleSelectSectorMember = (memberId) => {
        if (sectorMembersSelected.includes(memberId)) {
            return setSectorMembersSelected(sectorMembersSelected.filter(member => member !== memberId))
        }
        setSectorMembersSelected([...sectorMembersSelected, memberId])
    }

    const handleSubmitChanges = () => {
        const changes = getChanges(props.sector.members, sectorMembersSelected)
        const addedMembers = changes.added
        const removedMembers = changes.removed
        const requestBody = {
            action: "change_members",
            added: addedMembers,
            removed: removedMembers
        }

        request(`sectors/${props.sector.id}/`, 'PUT', requestBody).then(res => {
            if (res.success) {
                dispatch(updateSector(res.content))
            }
            props.onClose()
        })
    }

    if (!saveChanges) {
        return (
            <>
                <div className={"SectorMembers"}>
                    {
                        members ? <div className={"SearchContainer"}>
                            <SearchableDropdown
                                id={"AgencyMemberSelect"}
                                options={membersOptions}
                                label={"name"}
                                handleChange={(selected) => handleSelectSectorMember(selected.id)}
                                onFocusEnter={() => {}}
                            />
                        </div> : null
                    }

                    <div className={"SelectedMembers"}>
                        {
                            sectorMembersSelected.map(memberId => {
                                const member = getMember(memberId)
                                return <div className={"Item"}>
                                    <div style={{display: "flex", flexDirection: 'column'}}>
                                        <span>{member.name}</span>
                                        <span style={{fontSize: 12 }}>{member.email}</span>
                                    </div>
                                    <IoMdCloseCircle onClick={() => handleRemoveSectorMember(memberId)}/>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={"ButtonsContainer"}>
                    <Button variant="contained" onClick={props.onClose}>Fechar</Button>
                    <Button variant="contained" onClick={() => setSaveChanges(true)}>Salvar</Button>
                </div>
            </>
        );
    }

    const changes = getChanges(props.sector.members, sectorMembersSelected)

    return <div>
        <span>Tem certeza que deseja salvar as seguintes alterações?</span>
        {changes.added.length > 0 ? <div>
            <span>Adicionados:</span>
            <ul>
                {
                    changes.added.map(added => {
                        return <li>{getMember(added).name}</li>
                    })
                }
            </ul>
        </div> : null }
        {changes.removed.length > 0 ? <div>
            <span>Removidos:</span>
            <ul>
                {
                    getChanges(props.sector.members, sectorMembersSelected).removed.map(removed => {
                        return <li>{getMember(removed).name}</li>
                    })
                }
            </ul>
        </div> : null}
        <Button variant="contained" onClick={() => setSaveChanges(false)}>Voltar</Button>
        <Button variant="contained" onClick={handleSubmitChanges}>Confirmar</Button>
    </div>
}

export default SectorMembers;
