import React, {useMemo, useState} from 'react';
import "./styles.scss";
import UserImage from "../../img/user_avatar.png"
import {useSelector} from "react-redux";
import {FormGroup, FormLabel, TextField} from "@mui/material";
import Button from "../../Components/Button"
import ChangePassword from "./ChangePassword";
import {FaTriangleExclamation, FaPix, FaCreditCard, FaFileInvoice, FaArrowDown} from "react-icons/fa6";
import Table from "../../Components/Table"

const paymentOptions = [
    {
        'name': "Crédito",
        'icon': FaCreditCard
    },
    {
        'name': "Boleto",
        'icon': FaFileInvoice
    },
    {
        'name': "Pix",
        'icon': FaPix
    }
]

function Settings(props) {

    const {user} = useSelector((state) => state.app);

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [paymentOption, selectPaymentOption] = useState(false);
    const [hasActivePayment, setHasActivePayment] = useState(false);

    const hasChanges = useMemo(() => (name !== user.name || email !== user.email), [name, email]);

    return (
        <div className={"Settings"}>
            <div className={"SettingsSection User"}>
                <FormGroup>
                    <FormLabel>Foto de Perfil</FormLabel>
                    <div className={"ProfilePicture"}>
                        <img className={"ProfilePicturePicture"} src={UserImage} alt={user?.name}/>
                        <div className={"ProfilePictureOptions"}>
                            <Button
                                variant={"contained"}
                            >
                                Trocar imagem
                            </Button>
                            <Button
                                variant={"contained"}
                            >
                                Remover Imagem
                            </Button>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Senha</FormLabel>
                    <ChangePassword/>
                </FormGroup>
                <div style={{
                    borderBottom: "1px solid lightgray",
                }}></div>
                <FormGroup>
                    <FormLabel>Nome</FormLabel>
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>E-mail</FormLabel>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormGroup>
                <Button>Salvar</Button>
                {
                    hasChanges && <div style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 15
                    }}>
                        <FaTriangleExclamation color={"orange"}/>
                        Alterações não salvas
                    </div>
                }
            </div>
            <div className={"SettingsSection Billing"}>
                <div className={"Plan"}>
                    <span className={"Name"}>
                        Plano Básico Masterprod
                    </span>
                    <span className={"Price"}>
                        64,90/Mês
                    </span>
                </div>
                <div className="Payment">
                    <h3>Método de Pagamento</h3>
                    <div className={"PaymentMethod"}>
                        {
                            paymentOptions.map(option => (
                                <div
                                    className={`PaymentOption${paymentOption === option.name ? " Selected" : ""}`}
                                    onClick={() => selectPaymentOption(paymentOption === option.name ? false : option.name)}
                                >
                                    <option.icon
                                        size={20}
                                        color={"white"}
                                    />
                                    {option.name}
                                </div>
                            ))
                        }
                    </div>
                    {
                        hasActivePayment ? <>
                        <h3>Faturas</h3>
                        <Table
                            columns={[
                                {
                                    name: "Referência",
                                    key: 'month',
                                },
                                {
                                    name: "Vencimento",
                                    key: 'due',
                                },
                                {
                                    name: "Status",
                                    key: 'status',
                                },
                                {
                                    name: "Baixar",
                                    key: 'download',
                                }
                            ]}
                            compact
                            data={[
                                {
                                    month: "Julho",
                                    due: "10/08/2024",
                                    status: "Pago",
                                    download: <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: 25,
                                        width: 25,
                                        borderRadius: 5,
                                        backgroundColor: "#3f0b0e",
                                        cursor: "pointer"
                                    }}>
                                        <FaArrowDown
                                            color={"white"}
                                        />
                                    </div>
                                },
                                {
                                    month: "Agosto",
                                    due: "10/09/2024",
                                    status: "Em Aberto",
                                    download: <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: 25,
                                        width: 25,
                                        borderRadius: 5,
                                        backgroundColor: "#3f0b0e",
                                        cursor: "pointer"
                                    }}>
                                        <FaArrowDown
                                            color={"white"}
                                        />
                                    </div>
                                }
                            ]}
                        />
                    </> : <>
                            <h3>Detalhes do Pagamento</h3>
                            {
                                !paymentOption && <div>
                                    Selecione um Método de Pagamento
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Settings;
