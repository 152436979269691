import React, {useState} from 'react';
import {
    Button,
    Modal,
    CircularProgress,
    IconButton,
    Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import request from "../../Services/Request";
import {useDispatch, useSelector} from "react-redux";
import {FaGear, FaTrash, FaX} from "react-icons/fa6";
import SectorMembers from "./SectorMembers";
import {removeSector} from "./SectorsSlice";
import Table from "../../Components/Table";
import {FaCheck, FaHistory} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import Sector from "./Sector";

const SectorCrud = () => {
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const [selectedSector, setSelectedSector] = useState(false);

    const { selectedTeam } = useSelector((state) => state.app)
    const { sectors } = useSelector((state) => state.sectors)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const deleteSector = async (id) => {
        setDeleteConfirmationId(id);
    };

    const confirmDelete = async () => {
        if (deleteConfirmationId) {
            await request(`teams/${selectedTeam.id}/sectors/${deleteConfirmationId}/`, 'DELETE').then(res => {
                if (res.success) {
                    dispatch(removeSector(deleteConfirmationId));
                }
            });
            setDeleteConfirmationId(null);
        }
    };

    const cancelDelete = () => {
        setDeleteConfirmationId(null);
    };

    const tableColumns = [
        {
            name: 'Nome',
            key: 'name'
        },
        {
            name: 'Código',
            key: 'code'
        },
        {
            name: 'Prime',
            key: 'prime'
        }
    ]

    const membersActions = [
        {
            icon: <Tooltip title={"Ver histórico da agência"}>
                <IconButton>
                    <FaHistory color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            onClick: id => navigate(`/history/?type=sector&id=${id}`)
        },
        {
            icon: <Tooltip title={"Configurações da agência"}>
                <IconButton>
                    <FaGear color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            // onClick: (id) => setSelectedSector(sectors.find(sector => sector.id === id))
            onClick: (id) => setIsSettingsModalOpen(sectors.find(sector => sector.id === id))
        },
        {
            icon: <Tooltip title={"Excluir Agência"}>
                <IconButton>
                    <FaTrash color={"white"} size={18}/>
                </IconButton>
            </Tooltip>,
            onClick: deleteSector
        }
    ]

    const sectorsSorted = sectors ? [...sectors].sort((a, b) => a.name > b.name ? 1 : -1).map(sector => {
        return {
            id: sector.id,
            name: sector.name,
            code: sector.code,
            prime: sector.is_prime ? <FaCheck color={"green"} size={20}/> : <FaX color={"red"} size={20}/>
        }
    }) : []

    return (
        <div style={{
            height: '100%'
        }}>

            {
                !selectedSector ? <div style={{
                    height: '80%'
                }}>
                    {sectors ? <Table compact columns={tableColumns} data={sectorsSorted} actions={membersActions}/>
                        : <CircularProgress/>
                    }
                </div> : <Sector
                    sector={selectedSector}
                />
            }

            <Modal open={isSettingsModalOpen} onClose={() => setIsSettingsModalOpen(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                    <h2>Configurações de Agência</h2>
                    <h3>Membros</h3>
                    <SectorMembers onClose={() => setIsSettingsModalOpen(false)} sector={isSettingsModalOpen}/>
                </Box>
            </Modal>

            <Modal open={!!deleteConfirmationId} onClose={cancelDelete}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                    <h2>Confirmar Exclusão</h2>
                    <p>Deseja realmente excluir esta Agência?</p>
                    <Button variant="contained" color="error" onClick={confirmDelete}>Confirmar</Button>
                    <Button variant="contained" onClick={cancelDelete}>Cancelar</Button>
                </Box>
            </Modal>
        </div>
    );
};

export default SectorCrud;
