import React, {useState} from 'react';
import {Button, FormGroup, Modal, TextField} from "@mui/material";
import {Box} from "@mui/system";
import request from "../../Services/Request";
import {addSector} from "./SectorsSlice";
import {useDispatch, useSelector} from "react-redux";

function CreateSector(props) {

    const [sectorName, setSectorName] = useState('');
    const [sectorCode, setSectorCode] = useState('');
    const [blockAt, setBlockAt] = useState('');
    const [isPrime, setIsPrime] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { selectedTeam } = useSelector(state => state.app)

    const dispatch = useDispatch()

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        if (!validateSectorForm()) return
        const newSector = {
            name: sectorName,
            code: sectorCode,
            block_at: parseFloat(blockAt),
            team: selectedTeam.id,
            is_prime: isPrime
        };
        await request(`teams/${selectedTeam.id}/sectors/`, 'POST', newSector).then(res => {
            if (res.success) {
                dispatch(addSector(res.content))
            }
        });
        setSectorName('');
        setSectorCode('');
        setBlockAt('');
        closeModal();
    };

    const validateSectorForm = () => {
        return sectorName.trim() !== '' && sectorCode.trim() !== ''
    }

    return (
        <>
            <Button className={"HeaderActionButton"} variant="contained" onClick={openModal}>Adicionar Agência</Button>
            <Modal open={isModalOpen} onClose={closeModal}>
                <>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                        <h2>Adicionar Agência</h2>
                        <FormGroup>
                            <TextField label="Nome da Agência" value={sectorName} onChange={(e) => setSectorName(e.target.value)} fullWidth margin="normal" />
                            <TextField label="Código da Agência" value={sectorCode} onChange={(e) => setSectorCode(e.target.value)} fullWidth margin="normal" />
                            {/*<TextField label="Horário Máximo" type="number" step="0.01" value={blockAt} onChange={(e) => setBlockAt(e.target.value)} fullWidth margin="normal" />*/}
                            {/*<FormControlLabel control={<Checkbox value={isPrime} onChange={(e) => setIsPrime(e.target.value)} fullWidth margin="normal" />} label={"Possui espaço prime"}/>*/}
                            <Button variant="contained" onClick={handleSubmit}>Criar</Button>
                        </FormGroup>
                    </Box>
                </>
            </Modal>
        </>
);
}

export default CreateSector;