import React from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import TabContent from "../../Components/Tabs/TabContent";
import "./style.scss";

function Sector(props) {
    const {sector} = props

    const [selectedTab, setSelectedTab] = React.useState(0)
    const [sectorData, setSectorData] = React.useState(sector)

    const handlePropertyChange = (e) => {
        const property = e.target.name
        setSectorData({
            ...sector,
            [property]: e.target.value
        })
    }

    return (
        <div className={"Sector"}>
            <div className={"SectorTitle"}>Agência {sector.name} - {sector.code}</div>
            <div className={"SectorContainer"}>
                <Tabs
                    value={selectedTab}
                    onChange={(e, value) => setSelectedTab(value)}
                >
                    <Tab label="Detalhes"/>
                    <Tab label="Corretores"/>
                    <Tab label="Sub Setores"/>
                </Tabs>

                <div className={"SectorContent"}>
                    <TabContent value={selectedTab} index={0}>
                        <FormGroup style={{maxWidth: 400, height: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', rowGap: 20}}>
                            <FormControl>
                                <TextField
                                    id="sectorName"
                                    value={sectorData.name}
                                    name={"name"}
                                    onChange={(e) => handlePropertyChange(e)}
                                    variant={'outlined'}
                                    label="Nome da agência"
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="sectorCode"
                                    value={sectorData.code}
                                    name={"code"}
                                    onChange={(e) => handlePropertyChange(e)}
                                    label={"Código da Agência"}
                                />
                            </FormControl>
                            <FormControlLabel
                                style={{width: 150, justifyContent: 'flex-end'}}
                                label={"É prime?"}
                                control={<Checkbox
                                    id="sectorIsPrime"
                                    value={sectorData.is_prime}
                                    name={"is_prime"}
                                    onChange={(e) => handlePropertyChange(e)}
                                    variant={'outlined'}
                                />}
                                labelPlacement={"start"}
                            />
                        </FormGroup>
                    </TabContent>

                    <TabContent value={selectedTab} index={1}>
                        MEMBROS
                    </TabContent>

                    <TabContent value={selectedTab} index={2}>
                        SUB SETORES
                    </TabContent>
                </div>
            </div>
        </div>
    );
}

export default Sector;