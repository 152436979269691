import React, {useEffect, useState} from 'react';
import '../Styles/History.scss';
import DropdownSelector from "./DropdownSelector";
import {useDispatch, useSelector} from "react-redux";
import Table from "./Table";
import dayjs from "dayjs";
import {setAlert} from "../AppSlice";
import {currencyFormat} from "../Services/helpers";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import Modal from "./Modal";
import {FaFileExcel, FaFilePdf} from "react-icons/fa";
import Button from "./Button";
import request from "../Services/Request";

export const months = [
    { key: 1, value: "Janeiro"},
    { key: 2, value: "Fevereiro"},
    { key: 3, value: "Março"},
    { key: 4, value: "Abril"},
    { key: 5, value: "Maio"},
    { key: 6, value: "Junho"},
    { key: 7, value: "Julho"},
    { key: 8, value: "Agosto"},
    { key: 9, value: "Setembro"},
    { key: 10, value: "Outubro"},
    { key: 11, value: "Novembro"},
    { key: 12, value: "Dezembro"},
]

export const years = [
    2020,
    2021,
    2022,
    2023,
    2024
]

function History() {

    const [detailsId, setDetailsId] = useState(null);
    const [historyType, setHistoryType] = useState('team');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [monthData, setMonthData] = useState(false);
    const [title, setTitle] = useState("");
    const [productionType, setProductionType] = useState("T");

    const {productions, userRole} = useSelector(state => state.app)
    const {products} = useSelector(state => state.products)
    const {members} = useSelector(state => state.members)
    const {sectors} = useSelector(state => state.sectors)
    const [searchParams] = useSearchParams()
    const location = useLocation()

    useEffect(() => {
        if (!products || !productions || (userRole === "admin" && !members) || !sectors) return
        setTitle(getTitle())
        const daysInMonth = 30
        const result = []
        const totals = {}
        for(let day = 1; day <= daysInMonth; day++) {
            const dayData = {
                day
            }
            products.forEach(product => {
                const productProductions = productions.filter(production => {
                    const date = dayjs(production.date)
                    const isPrime = sectors.find(sector => sector.id === production.sector)?.is_prime
                    const isMemberType = historyType === 'member';
                    const isSectorType = historyType === 'sector';
                    return production.product === product.id &&
                        date.month() + 1 === selectedMonth &&
                        date.year() === selectedYear &&
                        date.date() === day &&
                        (
                            (!isMemberType && !isSectorType) ||
                            (isMemberType && production.member === detailsId) ||
                            (isSectorType && production.sector === detailsId)
                        ) &&
                        (
                            (productionType === "T") ||
                            (productionType === "P" && isPrime) ||
                            (productionType === "V" && !isPrime)
                        )
                })
                const amount = productProductions.reduce((acc, item) => acc += Number(item.amount), 0)
                dayData[product.id] = (product.type === "V" ? `${currencyFormat(amount)}` : amount)
                totals[product.id] = (totals[product.id] || 0) + amount
            })
            result.push(dayData)
        }

        const totalToAdd = {
            day: "Total",
            customStyle: {
                backgroundColor: "#d3d3d3",
                fontWeight: "bold"
            }
        }

        for (let total in totals) {
            const product = products.find(product => product.id === Number(total))
            const amount = totals[total]
            totalToAdd[total] = (product.type === "V" ? `${currencyFormat(amount)}` : amount)
        }

        result.unshift(totalToAdd)

        setMonthData(result);

    }, [selectedMonth, selectedYear, products, productions, historyType, detailsId, members, sectors, productionType]);
    useEffect(() => {
        const type = searchParams.get('type')
        if (type === 'sector' || type === 'member') {
            setHistoryType(type)
            setDetailsId(Number(searchParams.get('id')))
        } else {
            setHistoryType('team')
            setDetailsId(null)
        }
    }, [location]);

    const tableColumns = [
        {
            name: "Dia",
            key: 'day',
            small: true
        }
    ]

    products && products.forEach(product => {
        tableColumns.push({
            name: product.name,
            key: product.id
        })
    })

    const getTitle = () => {
        const titleType = searchParams.get('type')
        if (titleType === 'member' && detailsId) {
            return `Histórico de ${members.find(member => member.id === detailsId).name} em ${months.find(month => month.key === selectedMonth).value}`
        } else if (titleType === 'sector' && detailsId) {
            return `Histórico da agência ${sectors.find(sector => sector.id === detailsId).name} em ${months.find(month => month.key === selectedMonth).value}`
        } else {
            return `${userRole === "admin" ? "Histórico da equipe em" : "Seu histórico em"} ${months.find(month => month.key === selectedMonth).value}`
        }
    }

    return (
        <div className="History">
            <div className={"HistoryHeader"}>
                {(userRole === "admin" ? members : true) && sectors && <h2>{title}</h2>}
                <div className={"HistoryFilters"}>
                    <DropdownSelector
                        id={"history-type"}
                        label={"Tipo de produção"}
                        options={[{value: "Prime e Varejo", key: "T"}, {value: "Prime", key: "P"}, {value: "Varejo", key: "V"}]}
                        value={[{value: "Prime e Varejo", key: "T"}, {value: "Prime", key: "P"}, {value: "Varejo", key: "V"}].find(x => x.key === productionType).value}
                        onChange={(value) => setProductionType(value)}
                    />
                    <DropdownSelector
                        id={"history-month"}
                        label={"Selecione o mês"}
                        options={months}
                        value={months.find(month => month.key === selectedMonth).value}
                        onChange={(value) => setSelectedMonth(value)}
                    />
                    <DropdownSelector
                        id={"history-year"}
                        label={"Selecione o ano"}
                        options={years}
                        value={selectedYear}
                        onChange={(value) => setSelectedYear(value)}
                    />
                </div>
            </div>
            <div className={"HistoryBody"}>
                {
                    monthData && <Table
                    firstRowSticky
                    columns={tableColumns}
                    data={monthData}
                    compact
                />}
            </div>
        </div>
    );
}

export default History;

export const ExportHistory = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [reportType, setReportType] = useState(null);
    const {selectedTeam} = useSelector(state => state.app)
    const dispatch = useDispatch();

    const handleExportReport = async() => {
        if (!selectedMonth) {
            dispatch(setAlert({
                message: "Selecione o mês para gerar o relatório",
                severity: "warning"
            }))
        }

        if (!selectedYear) {
            dispatch(setAlert({
                message: "Selecione o ano para gerar o relatório",
                severity: "warning"
            }))
        }

        if (!reportType) {
            dispatch(setAlert({
                message: "Selecione o tipo de arquivo para gerar o relatório",
                severity: "warning"
            }))
        }

        const reportData = {
            month: selectedMonth,
            year: selectedYear,
            type: reportType,
            team: selectedTeam.id
        }
        const searchParams = new URLSearchParams(reportData);

        const res = await fetch(
            `https://api.masterprod.com.br/reports/production/?${searchParams.toString()}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("auth-token")}`
                }
            }
        ).then((response) => {
            return response.blob()
        })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                const monthName = months.find(month => month.key === selectedMonth).value;
                link.setAttribute(
                    'download',
                    `Produção de ${monthName}.${reportType === "pdf" ? "pdf" : "xlsx"}`,
                );
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
        // if (res.success) {
            return true
        // }
        // return false
    }

    const modalContent = <div className={"ProductionReport"}>
        <div className={"Section"}>
            <DropdownSelector
                options={months}
                label={"Mês"}
                value={months[selectedMonth - 1]?.value}
                onChange={(value) => setSelectedMonth(value)}
            />
        </div>
        <div className={"Section"}>
            <DropdownSelector
                options={years}
                label={"Ano"}
                value={selectedYear}
                onChange={(value) => setSelectedYear(value)}
            />
        </div>
        <div className={"Section FileType"}>
            <span className={"FileType Title"}>Tipo de Arquivo</span>
            <div className={"FileType Content"}>
                <Button
                    onClick={() => {setReportType(reportType === "excel" ? false : "excel")}}
                    style={reportType === "excel" ? {backgroundColor: "#3f2527"}: {}}
                >
                    <FaFileExcel/>
                    <span>Excel</span>
                </Button>
                <Button
                    onClick={() => {setReportType(reportType === "pdf" ? false : "pdf")}}
                    style={reportType === "pdf" ? {backgroundColor: "#3f2527"}: {}}
                >
                    <FaFilePdf/>
                    <span>PDF</span>
                </Button>
            </div>
        </div>
    </div>

    return <Modal
        buttonText={"Gerar Relatório"}
        title={"Relatório de Produção"}
        content={modalContent}
        style={{
            width: "25dvw",
        }}
        onConfirm={handleExportReport}
    />
}
