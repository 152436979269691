import React from 'react';
import './SidebarHeader.scss';
import UserImage from "../../img/user_avatar.png"
import {useSelector} from "react-redux";

export const SidebarHeader = ({collapsed} ) => {
    const { user } = useSelector(state => state.app)

    return (
        <div className={"SidebarHeaderContainer"}>
            <img className={`SidebarHeaderAvatar${collapsed ? ' small' : ''}`} alt={user.name} src={UserImage}/>
            {!collapsed ? <span className={"SidebarHeaderName"}>{user.name}</span> : null}
        </div>
    );
};