// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  padding: 20px; }
  .SidebarHeaderContainer > div {
    width: 100%;
    overflow: hidden; }

.SidebarHeaderAvatar {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out; }
  .SidebarHeaderAvatar.small {
    width: 40px;
    height: 40px; }
`, "",{"version":3,"sources":["webpack://./src/Components/SidebarMain/SidebarHeader.scss"],"names":[],"mappings":"AACE;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,aAAa,EAAA;EANd;IASG,WAAW;IACX,gBAAgB,EAAA;;AAIpB;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAOhB,2DAA2D,EAAA;EAhB5D;IAYG,WAAW;IACX,YAAY,EAAA","sourcesContent":[".SidebarHeader {\n  &Container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-around;\n    color: white;\n    padding: 20px;\n\n    > div {\n      width: 100%;\n      overflow: hidden;\n    }\n  }\n\n  &Avatar {\n    width: 80px;\n    height: 80px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    color: white;\n    font-size: 24px;\n    font-weight: 700;\n\n    &.small {\n      width: 40px;\n      height: 40px;\n    }\n\n    transition: height 0.2s ease-in-out, width 0.2s ease-in-out;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
