// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  background-color: #f8f8f8; }

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh; }

.PageTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 3px solid #ada9a9;
  margin-bottom: 10px 20px; }
  .PageTitle > h1 {
    margin: 0 0 0 15px; }

.PageContent {
  height: 90%;
  padding: 15px;
  box-sizing: border-box;
  width: 80dvw; }

button {
  padding: 20px;
  font-size: 14px; }

@media (max-width: 480px) {
  .PageContainer {
    width: 100%; }
  .PageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 3px solid #cecece; }
    .PageTitle > h1 {
      margin: 0 0 0 5px; }
  .PageContent {
    width: 100%;
    padding: 5px; } }
`, "",{"version":3,"sources":["webpack://./src/Styles/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,yBAAyB,EAAA;;AAIzB;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,cAAc,EAAA;;AAGhB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,gCAAgC;EAChC,wBAAwB,EAAA;EANzB;IAUG,kBAAkB,EAAA;;AAItB;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY,EAAA;;AAIhB;EACE,aAAa;EACb,eAAe,EAAA;;AAKjB;EAEI;IACE,WAAW,EAAA;EAGb;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,gCAAgC,EAAA;IALjC;MAQG,iBAAiB,EAAA;EAIrB;IACE,WAAW;IACX,YAAY,EAAA,EACb","sourcesContent":[".App {\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  display: flex;\n  background-color: #f8f8f8;\n}\n\n.Page {\n  &Container {\n    display: flex;\n    flex-direction: column;\n    width: 100dvw;\n    height: 100dvh;\n  }\n\n  &Title {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px;\n    border-bottom: 3px solid #ada9a9;\n    margin-bottom: 10px 20px;\n    //height: 7.5dvh;\n\n    > h1 {\n      margin: 0 0 0 15px;\n    }\n  }\n\n  &Content {\n    height: 90%;\n    padding: 15px;\n    box-sizing: border-box;\n    width: 80dvw;\n  }\n}\n\nbutton {\n  padding: 20px;\n  font-size: 14px;\n  //background-color: #0B3F3C !important;\n  //text-transform: capitalize !important;\n}\n\n@media (max-width: 480px) {\n  .Page {\n    &Container {\n      width: 100%;\n    }\n\n    &Title{\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      padding: 10px;\n      border-bottom: 3px solid #cecece;\n\n      > h1 {\n        margin: 0 0 0 5px;\n      }\n    }\n\n    &Content {\n      width: 100%;\n      padding: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
