import {Menu, MenuItem, Sidebar, useProSidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {SidebarHeader} from "./SidebarHeader";
import {FaBuilding, FaHistory, FaHome, FaShoppingBasket, FaUser} from "react-icons/fa";
import {SidebarFooter} from "./SidebarFooter";
import './Sidebar.scss';
import {FaChartColumn, FaGear, FaLinesLeaning, FaList, FaSheetPlastic} from "react-icons/fa6";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {GoGoal} from "react-icons/go";
import DropdownSelector from "../DropdownSelector";
import {setSelectedTeam, setShowSidebar} from "../../AppSlice";
import {getIsMobile} from "../../Services/helpers";

const MainSidebar = ({onLogout}) => {

    const { collapseSidebar, collapsed } = useProSidebar();
    const { path, userRole, teams, selectedTeam } = useSelector(state => state.app)

    const dispatch = useDispatch();

    // useEffect(() => {
    //     const isMobile = window.innerWidth <= 480;
    //     if (isMobile) {
    //         collapseSidebar(true)
    //     }
    // }, []);

    const menuItemStyles= {
        root: {
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        button: {
            marginBottom: 20,
            borderRadius: 6.25,
            width: '80%',
            backgroundColor: "#3F2527",
            paddingLeft: collapsed ? 14 : 20,
            '&:hover': {
                backgroundColor: "#253F3D",
                color: "white",
            }
        }
    };

    const userOptions = [
        {
            'route': '/',
            'name': "Geral",
            'icon': <FaHome/>
        },
        {
            route: "/production",
            name: "Enviar Produção",
            icon: <FaSheetPlastic/>
        },
        {
            'route': '/history',
            'name': "Histórico",
            'icon': <FaHistory/>
        }
    ]
    const adminOptions = [
        {
            'route': '/',
            'name': "Geral",
            'icon': <FaHome/>
        },
        {
            'route': '/productions',
            'name': "Produções",
            'icon': <FaList/>
        },
        {
            'route': '/members',
            'name': "Membros",
            'icon': <FaUser/>
        },
        {
            'route': '/sectors',
            'name': "Agências",
            'icon': <FaBuilding/>
        },
        {
            'route': '/products',
            'name': "Produtos",
            'icon': <FaShoppingBasket/>
        },
        {
            route: '/goals',
            name: "Objetivos",
            icon: <GoGoal/>
        },
        {
            route: "/production",
            name: "Enviar Produção",
            icon: <FaSheetPlastic/>
        },
        {
            route: '/history',
            name: "Histórico",
            icon: <FaHistory/>
        }
    ]

    const options = userRole === "admin" ? adminOptions : userOptions

    if (selectedTeam.leads) {
        options.splice(-1, 0,{
            route: "/leads",
            name: "Prospecções",
            icon: <FaChartColumn/>
        })
    }

    return <>
        <Sidebar
            backgroundColor={"#3F0B0E"}
            style={{
                position: getIsMobile() ? "absolute" : "relative",
                width: '5%',
                height: '100dvh',
                color: "white",
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div className={"SidebarContainer"}>
                <SidebarHeader collapsed={collapsed}/>
                <Menu
                    style={{height: '90%'}}
                    menuItemStyles={menuItemStyles}
                >
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 20
                    }}>
                        {
                            !collapsed && teams && teams.length > 1 && <DropdownSelector
                                label={"Selecione a equipe"}
                                options={teams ? teams.map(team => (
                                    {key: team.id, value: team.name}
                                )) : []}
                                value={selectedTeam.name}
                                onChange={(value) => dispatch(setSelectedTeam(teams.find(team => team.id === value)))}
                            />
                        }
                    </div>
                    {
                        options.map((option, i) => {
                            const optionStyles = option.route === path ? {backgroundColor: "#253F3D"} : {}
                            return <MenuItem
                                key={i}
                                style={optionStyles}
                                icon={option.icon}
                                component={<Link to={option.route} />}
                                onClick={() => getIsMobile() && dispatch(setShowSidebar(false))}
                            >
                                {option.name}
                            </MenuItem>
                        })
                    }
                </Menu>
                <SidebarFooter onLogout={onLogout} collapsed={collapsed}/>
            </div>
        </Sidebar>
        {getIsMobile() && <div className={"SidebarBackdrop"} onClick={() => dispatch(setShowSidebar(false))}></div>}
        </>
}

export default MainSidebar