import React, {useState} from 'react';
import {currencyFormat, getMember, getProgress} from "../../Services/helpers";
import ProgressBar from "../../Components/ProgressBar";
import {FaCaretDown, FaCaretUp, FaPlus} from "react-icons/fa6";
import {useSelector} from "react-redux";
import AddGoal from "./addGoal";

function GoalItem({sectorData, sector, selectedMonth, selectedYear, selectedProduct}) {

    const {goals} = useSelector(state => state.goals)
    const {productions} = useSelector(state => state.app)

    const [isExpanded, setIsExpanded] = useState(false)

    const getMemberGoals = (memberId, sectorId) => {
        const memberGoals = goals
            .filter(goal => goal.month === selectedMonth && goal.year === selectedYear && goal.product === selectedProduct && goal.member === memberId && goal.sector === sectorId)
            .reduce((acc, goal) => acc + Number(goal.amount), 0)

        const memberTotal = productions
            .filter(production => Number(production.date.split("-")[0]) === selectedYear && Number(production.date.split("-")[1]) === selectedMonth && production.product === selectedProduct && production.member === memberId && production.sector === sectorId)
            .reduce((acc, prod) => acc + Number(prod.amount), 0)

        const memberRemaining = memberGoals - memberTotal
        return {goals: memberGoals, total: memberTotal, remaining: memberRemaining}
    }

    console.log("SECTOR IS", sector)

    return (
        <div className={"Row"}>
            <div className={"RowContent"}>
                <div className={"Titles"}>
                    <span>{sector?.name}</span>
                    <span>{currencyFormat(sectorData.amount)}</span>
                    <span>{currencyFormat(sectorData.total)}</span>
                    <span
                        style={{
                            color: sectorData.remaining > 0 ? "red" : "green"
                        }}
                    >
                        {currencyFormat(sectorData.remaining)}
                    </span>
                </div>
                <div className={"Progress"}>
                    <ProgressBar
                        completed={getProgress(sectorData.amount, sectorData.total)}
                    />
                </div>
                <div className={"Icon"} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <FaCaretUp size={25}/> : <FaCaretDown size={25}/>}
                </div>
            </div>
            <div className={`RowCollapse${isExpanded ? " open" : ""}`}>
                {sector.members.map(member => {
                    const {goals, total, remaining} = getMemberGoals(member, sector.id);
                    return <div className={"RowContent"}>
                        <div className={"Titles sub"}>
                            <span>{getMember(member).name}</span>
                            <span>{currencyFormat(goals)}</span>
                            <span>{currencyFormat(total)}</span>vv
                            <span
                                style={{color: remaining > 0 ? "red" : "green"}}>{currencyFormat(remaining)}</span>
                        </div>
                        <div className={"Progress"}>
                            <ProgressBar completed={getProgress(goals, total)}/>
                        </div>
                        <div className={"Icon"}>
                            <AddGoal
                                product={selectedProduct}
                                member={member}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                sector={sector}
                            />
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default GoalItem;
