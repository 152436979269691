import React from 'react';
import Modal from "../../Components/Modal";
import {Alert, FormGroup, FormLabel, TextField} from "@mui/material";
import "./styles.scss"
import {clearAllListeners} from "@reduxjs/toolkit";
import request from "../../Services/Request";
import {setAlert} from "../../AppSlice";
import {useDispatch} from "react-redux";

function ChangePassword(props) {
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [error, setError] = React.useState("");

    const dispatch = useDispatch();

    const handleSubmit = async () => {
        setError("")
        if (!currentPassword) {
            setError("Senha atual precisa ser informada");
        }

        if (password !== confirmPassword) {
            setError("A senha nova e confirmação de senha estão divergentes")
        }

        const requestData = {
            current: currentPassword,
            new: password
        }

        const response = await request("user/password-change/", "POST", requestData)

        if (response.success) {
            dispatch(setAlert({message: "Senha alterada com sucesso!", severity: "success"}))
            setCurrentPassword("")
            setPassword("")
            setConfirmPassword("")
            return true
        }

        switch (response.error) {
            case "WRONG_CURRENT_PASSWORD":
                setError("Senha atual incorreta");
                break;
            default:
                setError("Erro ao alterar senha");
                break;
        }

        return false

    }

    const modalContent = <div className={"ChangePassword"}>
        <div className={"ChangePasswordField"}>
            <label>Senha Atual</label>
            <TextField
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"filled"}
            />
        </div>
        <div className={"ChangePasswordField"}>
            <label>Nova Senha</label>
            <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"filled"}
            />
        </div>
        <div className={"ChangePasswordField"}>
            <label>Confirme a nova senha</label>
            <TextField
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"filled"}
            />
        </div>
        {error && <Alert severity="error">{error}</Alert>}
    </div>

    return (
        <Modal
            title="Alterar senha"
            content={modalContent}
            buttonText={"Alterar Senha"}
            onConfirm={handleSubmit}
        />
    );
}

export default ChangePassword;
