import React, {useState} from 'react';
import Modal from "../../Components/Modal";
import {FormGroup, FormLabel, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import "./styles.scss";
import {currencyFormat} from "../../Services/helpers";
import Button from "../../Components/Button";
import products from "../Products/Products";
import DropdownSelector from "../../Components/DropdownSelector";
import {CurrencyInput} from "react-currency-mask";
import request from "../../Services/Request";
import {setAlert} from "../../AppSlice";
import {addLead, updateLead} from "./leadsSlice";
import {AddProductModal} from "./CreateLead";
import {FaPencil, FaTrash} from "react-icons/fa6";

function UpdateLead({initial}) {

    const [client, setClient] = useState(initial.client);
    const [selectedSector, setSelectedSector] = useState(initial.sector);
    const [addedProducts, setAddedProducts] = useState(initial.items);
    const [selectedMonth, setSelectedMonth] = useState(initial.month);
    const [obs, setObs] = useState(initial.obs);
    const [step, setStep] = useState(initial.step);
    const [toUpdate, setToUpdate] = useState([]);
    const [status, setStatus] = useState(initial.status);

    const {selectedTeam} = useSelector((state) => state.app);
    const {sectors} = useSelector((state) => state.sectors);
    const {products} = useSelector((state) => state.products);

    const dispatch = useDispatch();

    const handleAddProduct = (product, amount, code) => {
        setAddedProducts([...addedProducts, {id: product, amount, code}])
        return true
    }

    console.log("INITAL IS", initial)

    const handleSubmitLead = () => {
        const requestData = {
            client,
            month: selectedMonth,
            step,
            obs,
            items: addedProducts,
            team: selectedTeam.id,
            sector: selectedSector,
            toUpdate,
            status
        }

        request(`leads/${initial.id}/`, "PUT", requestData).then((response) => {
            if (response.success) {
                dispatch(setAlert({message: "Prospecção alterada com sucesso", severity: "success"}))
                dispatch(updateLead(response.content));
            } else {
                dispatch(setAlert({message: "Erro ao alterar prospecção", severity: "error"}))
            }
        })
        return true
    }

    const handleUpdateProduct = (product, amount, code, id) => {
        setToUpdate([...toUpdate, {id, code, amount, product}])
        return true
    }

    const modalContent = products && <div className="CreateLead">
        <div className={"Fields"}>
            <label>Empresa</label>
            <TextField
                value={client}
                onChange={(e) => setClient(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"outlined"}
            />
            <label>Agência</label>
            <DropdownSelector
                id={"createLeadSector"}
                value={sectors ? sectors.find(sec => sec.id === selectedSector)?.name : null}
                onChange={(value) => setSelectedSector(value)}
                options={sectors ? sectors.map(sec => ({key: sec.id, value: sec?.name})) : []}
                fullWidth={true}
            />
            <label>Mês Previsão de Débito</label>
            <DropdownSelector
                id={"createLeadMonth"}
                value={[
                    {key: 1, value: "Janeiro"},
                    {key: 2, value: "Fevereiro"},
                    {key: 3, value: "Março"},
                    {key: 4, value: "Abril"},
                    {key: 5, value: "Maio"},
                    {key: 6, value: "Junho"},
                    {key: 7, value: "Julho"},
                    {key: 8, value: "Agosto"},
                    {key: 9, value: "Setembro"},
                    {key: 10, value: "Outubro"},
                    {key: 11, value: "Novembro"},
                    {key: 12, value: "Dezembro"}
                ].find(month => month.key === selectedMonth)?.value}
                onChange={(value) => setSelectedMonth(value)}
                options={[
                    {key: 1, value: "Janeiro"},
                    {key: 2, value: "Fevereiro"},
                    {key: 3, value: "Março"},
                    {key: 4, value: "Abril"},
                    {key: 5, value: "Maio"},
                    {key: 6, value: "Junho"},
                    {key: 7, value: "Julho"},
                    {key: 8, value: "Agosto"},
                    {key: 9, value: "Setembro"},
                    {key: 10, value: "Outubro"},
                    {key: 11, value: "Novembro"},
                    {key: 12, value: "Dezembro"}
                ]}
                fullWidth
            />

            <label>Etapa</label>
            <TextField
                value={step}
                onChange={(e) => setStep(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"outlined"}
            />
            <label>Observações</label>
            <TextField
                value={obs}
                onChange={(e) => setObs(e.target.value)}
                style={{
                    width: "100%"
                }}
                variant={"outlined"}
            />
            <label>Status</label>
            <DropdownSelector
                id={"updateLeadStatus"}
                value={[
                    {key: "P", value: "Em Andamento"},
                    {key: "W", value: "Ganhou"},
                    {key: "L", value: "Perdeu"}
                ].find(statusf => statusf.key === status)?.value}
                onChange={(value) => setStatus(value)}
                options={[
                    {key: "P", value: "Em Andamento"},
                    {key: "W", value: "Ganhou"},
                    {key: "L", value: "Perdeu"}
                ]}
                fullWidth
            />
            <div className={"Products"}>
                <h3 className={"Title"}>Produtos em Negociação</h3>
                <div className={"Area"} style={{padding: 5, boxSizing: "border-box"}}>
                    {addedProducts.map((item) => {
                        console.log("ADDED PRODUCTS", item)
                        if (!item.product) return null
                        const product = products.find(product => product.id === item.product);
                        return <div key={product.id} className={"Item"}
                                    style={{display: "flex", justifyContent: "center", columnGap: 15}}>
                            {product?.name} - {currencyFormat(item.amount)} <UpdateProductModal
                            onConfirm={handleUpdateProduct} initial={item}/>
                        </div>
                    })}
                </div>
                <AddProductModal
                    onConfirm={handleAddProduct}
                />
            </div>
        </div>
    </div>

    return (
        <Modal
            title="Alterar Prospecção"
            content={modalContent}
            onConfirm={handleSubmitLead}
            buttonText={<FaPencil/>}
        />
    );
}

export default UpdateLead;


function UpdateProductModal({onConfirm, initial}) {
    const [selectedProduct, setSelectedProduct] = useState(initial.product);
    const [enteredAmount, setEnteredAmount] = useState(initial.amount);
    const [code, setCode] = useState(initial.code);
    const {products} = useSelector((state) => state.products);

    const modalContent = products && <div className="AddProduct">
        <FormGroup>
            <FormLabel>Processo</FormLabel>
            <TextField
                id={"createLeadAddProductCode"}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
            />
        </FormGroup>
        <FormGroup>
            <FormLabel>Produto</FormLabel>
            <DropdownSelector
                id={"createLeadAddProductProduct"}
                options={products.map(product => ({value: product.name, key: product.id}))}
                value={products.find(pr => pr.id === selectedProduct)?.name}
                onChange={(value) => setSelectedProduct(value)}
                fullWidth
            />
        </FormGroup>
        <FormGroup>
            <FormLabel>Valor</FormLabel>
            <CurrencyInput
                style={{
                    border: "1px solid lightgray",
                    backgroundColor: "white",
                    height: 20,
                    padding: 10,
                    cursor: "pointer",
                    color: "black",
                    borderRadius: 10,
                    width: '95.5%',
                }}
                value={enteredAmount}
                onChangeValue={(event, originalValue, maskedValue) => {
                    setEnteredAmount(originalValue)
                }}
            />
        </FormGroup>
    </div>

    return <Modal
        title="Editar Produto"
        content={modalContent}
        onConfirm={() => onConfirm(selectedProduct, enteredAmount, code, initial.id)}
        buttonText={<FaPencil/>}
    />
}
