// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SectorMembers {
  width: 100%;
  height: 250px;
  margin-bottom: 50px; }
  .SectorMembers .SearchContainer {
    width: 100%; }
  .SectorMembers .SelectedMembers .Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: lightgray;
    margin-top: 5px;
    border-radius: 2px;
    font-weight: bold; }

.ButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-self: flex-end; }
`, "",{"version":3,"sources":["webpack://./src/Pages/Sectors/SectorMembers.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB,EAAA;EAHrB;IAMI,WAAW,EAAA;EANf;IAWM,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,2BAA2B;IAC3B,eAAe;IACf,kBAAkB;IAClB,iBAAiB,EAAA;;AAKvB;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB,EAAA","sourcesContent":[".SectorMembers {\n  width: 100%;\n  height: 250px;\n  margin-bottom: 50px;\n\n  .SearchContainer {\n    width: 100%;\n  }\n\n  .SelectedMembers {\n    .Item {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      padding: 5px;\n      background-color: lightgray;\n      margin-top: 5px;\n      border-radius: 2px;\n      font-weight: bold;\n    }\n  }\n}\n\n.ButtonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-self: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
