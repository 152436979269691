import React, {useMemo, useState} from 'react';
import {Button, FormControlLabel, FormGroup, Modal, TextField} from "@mui/material";
import {Box} from "@mui/system";
import {FaPlus} from "react-icons/fa6";
import {getMember, getProduct} from "../../Services/helpers";
import request from "../../Services/Request";
import {useDispatch, useSelector} from "react-redux";
import {addGoal, updateGoal} from "./goalsSlice";
import {FaEdit} from "react-icons/fa";

function GoalItem({product, sector, member, selectedMonth, selectedYear}) {

    const {goals} = useSelector(state => state.goals)
    const [isOpen, setIsOpen] = useState(false)
    const [amount, setAmount] = useState(0)
    const productName = getProduct(product)?.name
    const memberName = getMember(member)?.name

    const activeGoals = useMemo(() => goals.filter(goal => (
            goal.product === product && goal.sector === sector.id && goal.member === member
            && goal.month === selectedMonth && goal.year === selectedYear
        )
    ), [goals, product, sector.id, member, selectedMonth, selectedYear])

    const hasActiveGoal = useMemo(() => activeGoals.length > 0, [activeGoals])
    if (hasActiveGoal && !amount) {
        setAmount(activeGoals[0].amount)
    }

    const {selectedTeam} = useSelector(state => state.app)
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const requestBody = {
            month: selectedMonth,
            year: selectedYear,
            sector: sector.id,
            product,
            member,
            amount
        }

        request(`teams/${selectedTeam.id}/goals/`, hasActiveGoal ? 'PUT' : 'POST', requestBody).then(res => {
            if (res.success) {
                hasActiveGoal ? dispatch(updateGoal(res.content)) : dispatch(addGoal(res.content))
                setIsOpen(false);
            }
        })
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#3F0B0E",
                    borderRadius: 10,
                    width: 25,
                    height: 25,
                    padding: 5
                }}
                onClick={() => setIsOpen(true)}
            >
                { hasActiveGoal ? <FaEdit color={"white"} size={20}/> : <FaPlus color={"white"} size={20}/>}
            </div>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                    <h2>Adicionar Objetivo</h2>
                    <h4>Objetivo de {productName} para {memberName}</h4>
                    <FormGroup>
                        <TextField label="Valor" type="number" step="0.01" value={amount} onChange={(e) => setAmount(e.target.value)} fullWidth margin="normal" />
                        <Button variant="contained" onClick={handleSubmit}>{hasActiveGoal ? 'Alterar' : 'Criar'}</Button>
                    </FormGroup>
                </Box>
            </Modal>
        </>
)
}

export default GoalItem;
