// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HistoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px; }

.HistoryFilters {
  display: flex;
  align-items: center;
  column-gap: 15px;
  max-height: 50px;
  overflow: visible; }

.HistoryBody {
  height: 78dvh;
  width: 96%;
  overflow: hidden;
  margin-bottom: 25px; }

.ProductionReport {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 15px; }
  .ProductionReport .Section {
    width: 100%; }
    .ProductionReport .Section .FileType {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .ProductionReport .Section .FileType.Title {
        margin: 8px 0;
        text-align: left;
        font-size: 14px; }
      .ProductionReport .Section .FileType.Content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%; }
      .ProductionReport .Section .FileType.Option {
        width: 50px;
        background-color: #0B3F3C;
        color: white;
        padding: 10px;
        border-radius: 10px; }

@media (max-width: 480px) {
  .HistoryHeader {
    margin-bottom: 25px;
    width: 95%; } }
`, "",{"version":3,"sources":["webpack://./src/Styles/History.scss"],"names":[],"mappings":"AACE;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB,EAAA;;AAGnB;EACE,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,mBAAmB,EAAA;;AAIvB;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,aAAa,EAAA;EALf;IASI,WAAW,EAAA;IATf;MAYM,WAAW;MACX,aAAa;MACb,sBAAsB,EAAA;MAd5B;QAiBQ,aAAa;QACb,gBAAgB;QAChB,eAAe,EAAA;MAnBvB;QAuBQ,aAAa;QACb,mBAAmB;QACnB,8BAA8B;QAC9B,WAAW,EAAA;MA1BnB;QA8BQ,WAAW;QACX,yBAAyB;QACzB,YAAY;QACZ,aAAa;QACb,mBAAmB,EAAA;;AAO3B;EAEI;IACE,mBAAmB;IACnB,UAAU,EAAA,EACX","sourcesContent":[".History {\n  &Header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 25px;\n  }\n\n  &Filters {\n    display: flex;\n    align-items: center;\n    column-gap: 15px;\n    max-height: 50px;\n    overflow: visible;\n  }\n\n  &Body {\n    height: 78dvh;\n    width: 96%;\n    overflow: hidden;\n    margin-bottom: 25px;\n  }\n}\n\n.ProductionReport {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  row-gap: 15px;\n\n\n  .Section {\n    width: 100%;\n\n    .FileType {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n\n      &.Title {\n        margin: 8px 0;\n        text-align: left;\n        font-size: 14px;\n      }\n\n      &.Content {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        width: 100%;\n      }\n\n      &.Option {\n        width: 50px;\n        background-color: #0B3F3C;\n        color: white;\n        padding: 10px;\n        border-radius: 10px;\n\n      }\n    }\n  }\n}\n\n@media (max-width: 480px) {\n  .History {\n    &Header {\n      margin-bottom: 25px;\n      width: 95%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
