import React, {useMemo, useState} from 'react';
import "./style.scss";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {setActiveDropdown} from "../../AppSlice";

function DropdownSelector(props) {
    const {options, label, value, checkout, fullWidth, id, compact, lessRadius} = props

    const { activeDropdown } = useSelector(state => state.app)

    const isExpanded = useMemo(() => activeDropdown === id, [activeDropdown])
    const dispatch = useDispatch();

    const handleSelect = (option) => {
        toggleActive();
        if (props.onChange) props.onChange(option)
    }

    const toggleActive = () => {
        dispatch(setActiveDropdown(isExpanded ? null : id));
    }

    console.log("OPTIONS IS", options)

    let containerStyle = {}
    let innerStyle = {}

    if (checkout || fullWidth) {
        containerStyle.width = '100%'
        if (!lessRadius) innerStyle.borderRadius = 10
    }

    if (props.containerStyle) {
        containerStyle = {...containerStyle, ...props.containerStyle};
    }

    if (props.innerStyle) {
        innerStyle = {...innerStyle, ...props.innerStyle};
    }

    return (
        <div className={"DropdownContainer"} style={containerStyle}>
            {label && <div className={"DropdownLabel"}>{label}</div>}
            <div className={`DropdownSelector${compact ? " Compact" : ""}`} onClick={toggleActive} style={innerStyle}>
                <span>{value}</span>
                {isExpanded ? <IoMdArrowDropup size={18}/> : <IoMdArrowDropdown size={20} color={"rgba(0, 0, 0, 0.54)"}/>}
            </div>
            <div className={`DropdownOptions${isExpanded ? ' Expanded' : ''}${compact ? " Compact" : ""}`}>
                {options.map(option => {
                    const isObj = typeof (option) === "object"
                    return <div className={"DropdownOption"} style={fullWidth && {width: "100%"}} onClick={() => handleSelect(isObj ? option.key : option)}>{isObj ? option.value : option}</div>
                })}
            </div>
        </div>
    )
}

export default DropdownSelector;