import HorizontalLogoTransparent from "../../img/HorizontalLogoTransparent.png"
import {Link} from "react-router-dom";
import "./navbar.scss";
import {FaBars} from "react-icons/fa6";
import {useMemo, useState} from "react";

function Navbar() {

    const [isExpanded, setIsExpanded] = useState(false);

    const links = useMemo(() => [
        {
            name: "Funcionalidades",
            href: "#Features"
        },
        {
            name: "Planos",
            href: "#Plans"
        },
        {
            name: "Solicitar Teste Gratuito",
            href: "#Contact",
            outlined: true
        },
        {
            name: "Entrar",
            href: "login/",
            isRedirect: true,
            outlined: true
        }
    ], [])

    return (
        <nav id={"IndexNav"}>
            <div className={"Full"}>
                <img src={HorizontalLogoTransparent} alt={"Logo masterprod"}/>
                <div className={"Links"}>
                    {
                        links.map(link => (
                            link.isRedirect ?
                                <Link to={link.href} className={link.outlined ? "Outlined" : ""}>{link.name}</Link> :
                                <a href={link.href} className={link.outlined ? "Outlined" : ""}>{link.name}</a>
                        ))
                    }
                </div>
                <Link to={"login/"} className={"Outlined"}>Entrar</Link>
                <FaBars size={30} className={"Icon"} onClick={() => setIsExpanded(!isExpanded)} color={"white"}/>
            </div>
            <div className={`Minimized${isExpanded ? ' Expanded' : ''}`}>
                {
                    links.map(link => (
                        link.isRedirect ?
                            <Link to={link.href} className={link.outlined ? "Outlined" : ""}>{link.name}</Link> :
                            <a href={link.href} className={link.outlined ? "Outlined" : ""}>{link.name}</a>
                    ))
                }
            </div>
        </nav>
    );
}

export default Navbar;
