import "../Styles/Header.scss"
import {FaBars} from "react-icons/fa6";
import React from "react";
import CreateMember from "../Pages/Members/CreateMember";
import CreateSector from "../Pages/Sectors/CreateSector";
import CreateProduct from "../Pages/Products/CreateProduct";
import {useSelector} from "react-redux";
import {getIsMobile} from "../Services/helpers";
import {ExportHistory} from "./History";
import {HasSentToday} from "../Pages/Dashboard/dashboard";
import CreateLead from "../Pages/Leads/CreateLead";
import {ExportLead} from "../Pages/Leads";

export default function Header(props) {

    const {path, selectedTeam, userRole} = useSelector(state => state.app)

    const isMobile = getIsMobile();
    const pageData= {
        "/": {
            title: `Geral - Equipe ${selectedTeam.name}`,
            action: !isMobile ? <HasSentToday container/> : null
        },
        "/productions": {
            title: "Produções",
        },
        "/members": {
            title: "Membros",
            action: <CreateMember/>
        },
        "/sectors": {
            title: "Agências",
            action: <CreateSector/>
        },
        "/products": {
            title: "Produtos",
            action: <CreateProduct/>
        },
        "/production": {
            title: "Produção"
        },
        "/goals": {
            title: "Objetivos"
        },
        "/history": {
            title: "Histórico",
            action: userRole === "admin" ? <ExportHistory/> : <></>
        },
        "/profile": {
            title: "Perfil"
        },
        "/settings": {
            title: "Configurações"
        },
        "/leads": {
            title: "Prospecções",
            action: <div style={{display: "flex", columnGap: 5}}><CreateLead/><ExportLead/></div>
        }
    }

    return(
        <div className={"PageTitle"}>
            { isMobile && <div><FaBars size={20} onClick={props.toggleSidebar}/></div>}
            <h1 style={ path === "/settings" ? {height: "5dvh", display: 'flex', alignItems: 'center', fontSize: 25} : {}}>{path === "/settings" ? pageData[path]['title'] : null}</h1>
            {pageData[path]['action']}
        </div>
    )
}
