import React, {useEffect, useState} from 'react';
import './styles.scss';
import Button from "../Button";
import {ThreeDots} from "react-loader-spinner";

export default function Modal({
    onConfirm,
    onClose,
    onOpen,
    buttonClass,
    buttonColor,
    buttonText,
    open,
    title,
    content,
    style,
    cancelText,
    noSubmit
}) {
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setShowModal(open !== undefined ? open : showModal)
    }, [open]);

    const handleOpenModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        onClose && onClose()
        setShowModal(false);
    }

    const handleConfirm = async() => {
        if (onConfirm) {
            setIsSubmitting(true);
            console.log("HANDLING CONFIRM 2")
            const success = await onConfirm();
            console.log("HANDLING CONFIRM 1", success)
            setIsSubmitting(false);
            if (success) {
                setShowModal(false);
            }
        } else {
            setShowModal(false);
        }
    };

    return (
        <div>
            {open === undefined && <Button
                className={buttonClass || ""}
                onClick={handleOpenModal}
                style={{
                    backgroundColor: buttonColor
                }}
            >
                {buttonText}
            </Button>}
            <div className={`modal ${showModal ? 'show' : ''}`}>
                <div
                    className="modal-content"
                    style={style ? style : {}}
                >
                    <div className="modal-header">
                    <span
                        style={{
                            fontSize: 16
                        }}
                    >
                        {title}
                    </span>
                    </div>
                    <div className="modal-body">
                        {content}
                    </div>
                    <div className="modal-footer">
                        <button className="cancel-button" onClick={handleCloseModal}>{cancelText ? cancelText : "CANCELAR"}</button>
                        {
                            !isSubmitting ?
                                !noSubmit && <button
                                    className="confirm-button"
                                    onClick={handleConfirm}
                                >
                                    CONFIRMAR
                                </button>
                                : <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 33,
                                }}>
                                    <ThreeDots
                                        color={"#1E4B4B"}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};