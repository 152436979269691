import React, {useEffect, useMemo, useState} from 'react';
import {
    Button, FormGroup,
    FormLabel,
    MenuItem, Modal,
    Select, TextField
} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { CurrencyInput } from 'react-currency-mask';
import request from "../../Services/Request";
import {
    addEmptyProductionFlag,
    addProductions,
    removeEmptyProductionFlag,
    removeProduction,
    setAlert
} from "../../AppSlice";
import {currencyFormat, getIsMobile} from "../../Services/helpers";
import "./sendProduction.scss"
import DropdownSelector from "../../Components/DropdownSelector";
import dayjs from "dayjs";
import {FaCircleXmark} from "react-icons/fa6";
import {Box} from "@mui/system";
import {FaExclamationTriangle} from "react-icons/fa";


const SendProduction = () => {

    const {selectedTeam, userRole, user, productions, emptyProductionFlags} = useSelector(state => state.app)
    const {products} = useSelector(state => state.products)
    const {sectors} = useSelector(state => state.sectors)
    const {members} = useSelector(state => state.members)

    const dispatch = useDispatch()

    const [selectedMember, setSelectedMember] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(false)
    const [selectedSector, setSelectedSector] = useState(false)
    const [enteredAmount, setEnteredAmount] = useState(false)

    const isMobile = getIsMobile()

    const handleAddProduction = () => {
        const productionToAdd = {
            member: selectedMember,
            sector: selectedSector,
            product: selectedProduct,
            amount: enteredAmount
        }

        const requestBody = {
            productions: [productionToAdd]
        }

        request(`teams/${selectedTeam.id}/production/`, 'POST', requestBody).then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Produção registrada com sucesso", severity: "success"}))
                dispatch(addProductions(res.content))
                setSelectedProduct(false)
                setSelectedSector(false)
                setEnteredAmount(false)
            } else {
                dispatch(setAlert({message: "Erro ao enviar produção", severity: "error"}))
            }
        })
    }

    const handleRemoveAddedProduction = (productionId) => {
        request(`teams/${selectedTeam.id}/production/${productionId}/`, 'DELETE').then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Produção removida com sucesso", severity: "success"}))
                dispatch(removeProduction(productionId))
            } else {
                dispatch(setAlert({message: "Erro ao remover produção", severity: "error"}))
            }
        })
    }

    useEffect(() => {
        if (sectors && sectors.length > 0) {
            setSelectedSector(sectors[0].id)
        }
    }, [sectors]);

    const todaysProduction = useMemo(() => productions.filter(production => production.member === user.id && dayjs().isSame(production.date, 'date')), [productions])

    const hasEmptyFlagToday = emptyProductionFlags && emptyProductionFlags.find(flag => dayjs().isSame(flag.date, 'date'))
    console.log("hasEmptyFlagToday", hasEmptyFlagToday, emptyProductionFlags)

    return (
        <div className={"SendProductionContainer"}>
            {
                hasEmptyFlagToday ? <div className={"EmptyFlag"}>
                    <FaExclamationTriangle color={"orange"} size={50}/>
                    <div>
                        Você informou a produção de hoje como zerada
                    </div>
                    <UndoEmptyFlag flag={hasEmptyFlagToday}/>
                </div> : sectors && products && <>
                    <div className={"SendProductionFormInputs"}>
                        { userRole === "admin" && <div className={"SendProductionFormInput"}>
                            <DropdownSelector
                                label={"Selecione o corretor"}
                                options={members.map(member => {
                                    return {value: member.name, key: member.id}
                                })}
                                value={members.find(member => member.id === selectedMember)?.name}
                                onChange={(value) => setSelectedMember(value)}
                                fullWidth
                                id={"submitMember"}
                            />
                        </div>}
                        <div className={"SendProductionFormInput"}>
                            <DropdownSelector
                                label={"Selecione a agência"}
                                options={sectors.map(sector => {
                                    return {value: sector.name, key: sector.id}
                                })}
                                value={sectors.find(sector => sector.id === selectedSector)?.name}
                                onChange={(value) => setSelectedSector(value)}
                                fullWidth
                                id={"submitSector"}
                            />
                        </div>
                        <div className={"SendProductionFormInput"}>
                            <DropdownSelector
                                label={"Selecione o produto"}
                                options={products.map(sector => {
                                    return {value: sector.name, key: sector.id}
                                })}
                                value={products.find(product => product.id === selectedProduct)?.name}
                                onChange={(value) => setSelectedProduct(value)}
                                fullWidth
                                id={"submitProduct"}
                            />
                        </div>
                        <div className={"SendProductionFormInput"}>
                            <FormLabel style={{height: "40%", zIndex: 1}} htmlFor={"amountInput"}>Informe o
                                valor</FormLabel>
                            <CurrencyInput
                                style={{
                                    border: "1px solid lightgray",
                                    backgroundColor: "white",
                                    height: 35,
                                    padding: 10,
                                    cursor: "pointer",
                                    color: "black",
                                    borderRadius: 10,
                                    width: '94%'
                                }}
                                value={enteredAmount}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setEnteredAmount(originalValue)
                                }}
                            />
                        </div>
                        <Button
                            className={"SendProductionFormButton"}
                            onClick={handleAddProduction}
                            variant={"contained"}
                        >
                            Registrar Produção
                        </Button>
                        {todaysProduction.length === 0 && !isMobile && <ConfirmEmptyFlag/>}
                    </div>
                    <div className={"SendProductionList"}>
                        <div style={{
                            // display: "flex",
                            // flexDirection: "column",
                            // justifyContent: "center",
                            // alignItems: 'center'
                        }}>
                            <h3 className={"SendProductionListTitle"}>Suas produções de hoje</h3>
                            <div className={"SendProductionListItems"}>
                                {todaysProduction.map(production => {
                                    return <div style={{
                                    display: "flex",
                                    backgroundColor: "#f0f0f0",
                                    margin: 5,
                                    padding: 5,
                                    width: '90%',
                                    borderRadius: 5,
                                    justifyContent: "space-between",
                                    alignItems: 'center'
                                }}>
                                    <div>
                                        {products.find(productFind => productFind.id === production.product)?.name}: {currencyFormat(production.amount)}
                                    </div>
                                    <div onClick={() => handleRemoveAddedProduction(production.id)}>
                                        <FaCircleXmark/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                { todaysProduction.length === 0 && isMobile && <ConfirmEmptyFlag/>}
                </>
            }
        </div>
    );
};

export default SendProduction;


const ConfirmEmptyFlag = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {selectedTeam} = useSelector(state => state.app)
    const dispatch = useDispatch()

    const handleSubmit = () => {
        request(`teams/${selectedTeam.id}/production/empty/`, 'POST').then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Produção zerada com sucesso", severity: 'success'}))
                dispatch(addEmptyProductionFlag(res.flag))
            } else {
                dispatch(setAlert({message: "Erro ao informar produção zerada", severity: 'error'}))
            }
            setIsModalOpen(false)
        })
    }

    return <>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                    <h2>Tem certeza que deseja informar produção zerada?</h2>
                    <div style={{display: 'flex', flexDirection: 'row', columnGap: 15}}>
                        <Button variant="contained" onClick={handleSubmit}>Confirmar</Button>
                        <Button variant="contained" onClick={() => setIsModalOpen(false)}>Voltar</Button>
                    </div>
                </Box>
        </Modal>
        <Button
            className={"SendProductionFormButton"}
            onClick={() => setIsModalOpen(true)}
            variant={"contained"}
        >
            Informar Produção Zerada
        </Button>
    </>
}


const UndoEmptyFlag = ({flag}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {selectedTeam} = useSelector(state => state.app)
    const dispatch = useDispatch()

    const handleSubmit = () => {
        request(`teams/${selectedTeam.id}/production/empty/${flag.id}/`, 'DELETE').then(res => {
            if (res.success) {
                dispatch(setAlert({message: "Desfeito com sucesso", severity: 'success'}))
                dispatch(removeEmptyProductionFlag(flag.id))
            } else {
                dispatch(setAlert({message: "Erro ao desfazer", severity: 'error'}))
            }
        })
    }

    return <>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, maxWidth: '75%', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                <h2>Tem certeza que deseja desfazer?</h2>
                <div style={{display: 'flex', flexDirection: 'row', columnGap: 15}}>
                    <Button variant="contained" onClick={handleSubmit}>Confirmar</Button>
                    <Button variant="contained" onClick={() => setIsModalOpen(false)}>Voltar</Button>
                </div>
            </Box>
        </Modal>
        <Button
            className={"SendProductionFormButton"}
            onClick={() => setIsModalOpen(true)}
            variant={"contained"}
            style={{
                width: '60%',
            }}
        >
            Desfazer
        </Button>
    </>
}