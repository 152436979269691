import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import History from "./Components/History";
import Sectors from "./Pages/Sectors/Sectors";
import Products  from "./Pages/Products/Products";
import Members from "./Pages/Members/Members";
import Dashboard from "./Pages/Dashboard/dashboard";
import { ProSidebarProvider } from "react-pro-sidebar";
import {Provider} from "react-redux";
import store from './store'
import SendProduction from "./Pages/SendProduction/SendProduction";
import Goals from "./Pages/Goals/Goals";
import Login from "./Pages/Login/Login";
import Checkout from "./Pages/Checkout/Checkout";
import Settings from "./Pages/Settings/Settings";
import ProductionDetails from "./Pages/ProductionDetails/ProductionDetails";
import Leads from "./Pages/Leads";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <div>Erro! Página não encontrada</div>,
        children: [
            {
                path: "/",
                element: <Dashboard/>
            },
            {
                path: "productions/",
                element: <ProductionDetails/>
            },
            {
                path: "history/",
                element: <History/>
            },
            {
                path: "sectors/",
                element: <Sectors/>
            },
            {
                path: "products/",
                element: <Products/>
            },
            {
                path: "members/",
                element: <Members/>
            },
            {
                path: "production/",
                element: <SendProduction/>
            },
            {
                path: "goals/",
                element: <Goals/>
            },
            {
                path: "login/",
                element: <Login/>
            },
            {
                path: "checkout/",
                element: <Checkout/>
            },
            {
                path: "settings/",
                element: <Settings/>
            },
            {
                path: "leads",
                element: <Leads/>
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <ProSidebarProvider>
              <RouterProvider router={router}/>
          </ProSidebarProvider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
