import store from '../store';

export function getIsMobile() {
    console.log("GETTING IS MOBILE", window.innerWidth)
    return window.innerWidth < 500
}

export function getProgress(current, total) {
    return !current? 100 : (total * 100) / current
}

export function floatToString(floatHours) {
    const hours = Math.floor(floatHours);
    const minutes = Math.round((floatHours - hours) * 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function getMember(memberId) {
    const { members } = store.getState().members
    if (!members) return {name: '', email: '', phone: ''};
    return members.find(member => member.id === memberId)
}

export const currencyFormat = (number) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(number)
}

export function getProduct(productId) {
    const { products } = store.getState().products
    if (!products) return {name: '', type: 'V'};
    return products.find(product => product.id === Number(productId))
}

export function groupEveryTwo(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 2) {
        result.push(arr.slice(i, i + 2));
    }

    return result;
}
