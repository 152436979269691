import React, { useEffect, useState } from 'react';
import { FaCircleInfo, FaCircleExclamation, FaTriangleExclamation } from 'react-icons/fa6';
import './style.scss';
import {useDispatch} from "react-redux";
import {setAlert} from "../../AppSlice";
import {FaCheck} from "react-icons/fa";

const Alert = ({ severity, message, show }) => {
    const [isVisible, setIsVisible] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (message) {
            setIsVisible(true)
            setTimeout(() => dispatch(setAlert({message: "", severity: ""})), 5000)
        } else {
            setIsVisible(false)
        }
    }, [message, severity]);

    let iconComponent;

    switch (severity) {
        case 'info':
            iconComponent = <FaCircleInfo />;
            break;
        case 'error':
            iconComponent = <FaCircleExclamation />;
            break;
        case 'alert':
            iconComponent = <FaTriangleExclamation />;
            break;
        case 'success':
            iconComponent = <FaCheck />;
            break;
        default:
            iconComponent = null;
    }

    console.log("ALERT", severity, message, iconComponent)

    return (
        <div className={`alert ${severity} ${isVisible ? 'show' : ''}`}>
            <span className="icon">{iconComponent}</span>
            <span className="message">{message}</span>
        </div>
    );
};

export default Alert;
