import React, { useState } from 'react';
import "./checkout.scss";
import NewBackground from "../../img/NewBackground.webp";
import InputMask from "react-input-mask";
import DropdownSelector from "../../Components/DropdownSelector";
import Cities from "../../Services/cities.json";
import States from "../../Services/states.json";
import {FaEye, FaEyeSlash} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {FaCheck, FaCheckCircle, FaExclamationTriangle} from "react-icons/fa";

function Checkout(props) {


    return (
        <div className={"Checkout"}>
            <img src={NewBackground} alt={"background image"} className={"CheckoutBackground"}/>
            <div className={"CheckoutContainer"}>
                <div className={"CheckoutHeader"}>
                    Plano
                </div>
                <div className={"CheckoutBody"}>
                    <div className={"CheckoutBodySection"}>
                        <div className={"PlanDetails"}>
                            <div className={"PlanDetailsTitle"}>
                                Padrão
                            </div>
                            <div className={"PlanDetailsBody"}>
                                <div className={"PlanDetailsBenefit"}>
                                    <div className={"PlanDetailsBenefitIcon"}>
                                        <FaCheckCircle color={"green"} size={25}/>
                                    </div>
                                    <span className={"PlanDetailsBenefitText"}>Gerencie a produção da sua equipe</span>
                                </div>
                                <div className={"PlanDetailsBenefit"}>
                                    <div className={"PlanDetailsBenefitIcon"}>
                                        <FaCheckCircle color={"green"} size={25}/>
                                    </div>
                                    <span className={"PlanDetailsBenefitText"}>Acompanhe seus objetivos</span>
                                </div>
                                <div className={"PlanDetailsBenefit"}>
                                    <div className={"PlanDetailsBenefitIcon"}>
                                        <FaCheckCircle color={"green"} size={25}/>
                                    </div>
                                    <span className={"PlanDetailsBenefitText"}>Compartilhe facilmente a produção do dia para o Whatsapp</span>
                                </div>
                                <div className={"PlanDetailsBenefit"}>
                                    <div className={"PlanDetailsBenefitIcon"}>
                                        <FaCheckCircle color={"green"} size={25}/>
                                    </div>
                                    <span className={"PlanDetailsBenefitText"}>Fácil acesso pelo celular ou computador</span>
                                </div>
                            </div>
                            <div className={"PlanDetailsPrice"}>
                                <span>R$ 69,99</span>
                                <span>O valor é cobrado mensalmente de forma automática</span>
                            </div>
                        </div>
                    </div>
                    <div className={"CheckoutBodySection"}>
                        <div className={"PaymentDetails"}>
                            <div className={"PaymentDetailsTitle"}>
                                Detalhes do cartão
                            </div>
                            <div className={"PaymentDetailsBody"}>
                                <div className={"PaymentDetailsInput"}>
                                    <div className={"PaymentDetailsInputField"}>
                                        <label>Nome do titular</label>
                                        <input/>
                                    </div>
                                    <div className={"PaymentDetailsInputField"}>
                                        <label>Número do cartão</label>
                                        <input/>
                                    </div>
                                    <div className={"PaymentDetailsInputField Split"}>
                                        <div className={"PaymentDetailsInputField SplitItem"}>
                                            <label>Validade</label>
                                            <input/>
                                        </div>
                                        <div className={"PaymentDetailsInputField SplitItem"}>
                                            <label>CVV</label>
                                            <input/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"PaymentDetailsFooter"}>
                                <span>Total:</span>
                                <span>R$ 69,99/mês</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"CheckoutFooter"}>
                    <button className={"CheckoutFooterButton return"}>
                        Voltar
                    </button>
                    <button className={"CheckoutFooterButton"}>
                        Finalizar Cadastro
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
